import React, {useState} from 'react'

import Form from "../subcomponents/forms/OrderForm"

import { useExchangeStore } from "../../stores/store_exchange";
import { useSettingsStore } from '../../stores/store_settings'
import { useUserSettingStore } from '../../stores/store_user_settings';
import MobileForm from '../subcomponents/forms/MobileForm';

function MobileTrade() {
    const exchanges_list = useExchangeStore((state) => state.exchanges_list)
    // const exchanges_list = {
    //   "Binance": "frd"
    // }
    const selectedSetting = useSettingsStore((state) => state.selectedSetting)
    const settings = useSettingsStore((state) => state.settings)
  
    const [strat, setStrat] = useState("algo")

    const handleChangeTrade = (e) => {
        setStrat(e.target.value)
      }
    const url = {
        'trade': "/api/make_trade",
        "algo": "/api/make_algo"
      }
    
    const inputs = {
        'trade': [
        "max_order_size",                       
        "trigger_price",
        "stop_price",
        "exit_price",
        "price_limit",
        "break_time_in",
        "break_time_out",
        "depth_min",
        "depth_max", 
        "cancel_price_gap",
        "price_limit_is_last_ex_price",
        "rdm_qty_range", 
        "aggro_order_on",
        "aggro_depth",
        "aggro_order_delay",
        "aggro_book_ratio",
        "aggro_forced_if_no_fill_in",
        "fill_delay",
        "algo_type",
        ],
        "algo": [
        "max_order_size",                      
        "trigger_price",
        "trigger_instrument",                   
        "price_limit",
        "price_limit_is_last_ex_price", 
        "fill_delay",                   
        "depth_min",
        "depth_max", 
        "cancel_price_gap",
        "rdm_qty_range", 
        "aggro_order_on",
        "aggro_depth",
        "aggro_order_delay",
        "aggro_book_ratio",
        "aggro_forced_if_no_fill_in",
        "algo_type",
        ]
    }
    const details = {
        'trade': {
        "targets": "Example: BTCUSDT:10000 \n ETHUSDT:-1000",
        "max_order_size": 'Order size will be capped at this value sometimes it can round slightly over if close to min_nominal',                      
        "trigger_price": 'Example: \n  >24000 <19000 \n >23000/>24000/>25000 \n default < if not specified', 
        "stop_price": 'closed if price < or > stop_price (depend on long/short) (can be added/changed later)', 
        "exit_price": 'closed if price < or > exit_price (depend on long/short) (can be added/changed later)', 
        "price_limit": 'Example: 24000 \n 22000/23000/24000 \n will never trade > or < to price_limit \n (do not specify < or >)', 
        "break_time_in": 'Example: 10 \n 10/20/30/40 \n if we spend X minutes over trigger_price', 
        "break_time_out": 'Example : 5 \n if X % under trigger_price will reset break_time_in', 
        "depth_min": 'Example: 0.001 \n how deep we post our order in the book (in %)',     
        "depth_max": 'Example: 0.002 \n random between depth_min/depth_max (must be >= depth_min)',     
        "price_limit_is_last_ex_price": 'Example : 0 or 1 \n our last exPrice become our price_limit \n',  
        "cancel_price_gap": 'Example: 0.0001 \n do not touch',      
        "rdm_qty_range": 'If max_order_size is 1000 and rdm_qty_range is 20 we will post an order size between 800 and 1000 ',     
        "aggro_order_on": 'Example : 0 or 1 \n will allow to be taker',     
        "aggro_depth": 'How deep we will try to get filled with aggro in %',     
        "aggro_order_delay": 'In seconds \n Max delay between aggro orders',     
        "aggro_book_ratio": 'Example : 5\n Ratio between bid/ask at 0.1% spread if bid vs ask ratio is > X  \n allow aggro order ',     
        "aggro_forced_if_no_fill_in": 'Will force an aggro order if you didnt get filled in X seconds',        
        "fill_delay": 'Will wait X seconds to send another order when filled', 
        },
        "algo": {
        "targets": "Example: BTCUSDT:10000 \n ETHUSDT:-1000",
        "max_order_size": 'Order size will be capped at this value sometimes it can round slightly over if close to min_nominal',                      
        "trigger_price": 'Example: \n  >24000 <19000 \n >23000/>24000/>25000 \n default < if not specified', 
        "trigger_instrument": 'If trigger_price is specified \n it will use the first instrument in target as default \n else you can use this instead', 
        "stop_price": 'closed if price < or > stop_price (depend on long/short) (can be added/changed later)', 
        "exit_price": 'closed if price < or > exit_price (depend on long/short) (can be added/changed later)', 
        "price_limit": 'Example: 24000 \n 22000/23000/24000 \n will never trade > or < to price_limit \n (do not specify < or >)', 
        "break_time_in": 'Example: 10 \n 10/20/30/40 \n if we spend X minutes over trigger_price', 
        "break_time_out": 'Example : 5 \n if X % under trigger_price will reset break_time_in', 
        "stop_max_order_size": 'Order size of your stops/exits',      
        "depth_min": 'Example: 0.001 \n how deep we post our order in the book (in %)',     
        "depth_max": 'Example: 0.002 \n random between depth_min/depth_max (must be >= depth_min)',     
        "price_limit_is_last_ex_price": 'Example : 0 or 1 \n our last exPrice become our price_limit \n',    
        "cancel_price_gap": 'Example: 0.0001 \n do not touch',   
        "rdm_qty_range": 'If max_order_size is 1000 and rdm_qty_range is 20 we will post an order size between 800 and 1000 ',     
        "aggro_order_on": 'Example : 0 or 1 \n will allow to be taker',     
        "aggro_depth": 'How deep we will try to get filled with aggro in %',     
        "aggro_order_delay": 'In seconds \n Max delay between aggro orders',     
        "aggro_book_ratio": 'Example : 5\n Ratio between bid/ask at 0.1% spread if bid vs ask ratio is > X  \n allow aggro order ',     
        "aggro_forced_if_no_fill_in": 'Will force an aggro order if you didnt get filled in X seconds',     
        "fill_delay": 'Will wait X seconds to send another order when filled',     
        "algo_type": 'Leave blank or algo_nom \n WARNING: algo_nom target amount is in coin size \n Example: BTCUSDT:1 => you will have 1 BTC'
        }
    }
    
    const stop_settings_list = [
    "max_order_size",                    
    "depth_min",
    "depth_max", 
    "cancel_price_gap",
    "rdm_qty_range", 
    "aggro_order_on",
    "aggro_depth",
    "aggro_order_delay",
    "aggro_book_ratio",
    "aggro_forced_if_no_fill_in",
    "fill_delay",
    ]
    const details_infos = {
      "max_order_size": 'Order size will be capped at this value sometimes it can round slightly over if close to min_nominal',                      
      "trigger_price": 'Example: \n  >24000 <19000 \n >23000/>24000/>25000 \n default < if not specified', 
      "trigger_instrument": 'If trigger_price is specified \n it will use the first instrument in target as default \n else you can use this instead', 
      "stop_price": 'closed if price < or > stop_price (depend on long/short) (can be added/changed later)', 
      "exit_price": 'closed if price < or > exit_price (depend on long/short) (can be added/changed later)', 
      "price_limit": 'Example: 24000 \n 22000/23000/24000 \n will never trade > or < to price_limit \n (do not specify < or >)', 
      "break_time_in": 'Example: 10 \n 10/20/30/40 \n if we spend X minutes over trigger_price', 
      "break_time_out": 'Example : 5 \n if X % under trigger_price will reset break_time_in', 
      "stop_max_order_size": 'Order size of your stops/exits',      
      "depth_min": 'Example: 0.001 \n how deep we post our order in the book (in %)',     
      "depth_max": 'Example: 0.002 \n random between depth_min/depth_max (must be >= depth_min)',     
      "price_limit_is_last_ex_price": 'Example : 0 or 1 \n our last exPrice become our price_limit \n',    
      "cancel_price_gap": 'Example: 0.0001 \n do not touch',   
      "rdm_qty_range": 'If max_order_size is 1000 and rdm_qty_range is 20 we will post an order size between 800 and 1000 ',     
      "aggro_order_on": 'Example : 0 or 1 \n will allow to be taker',     
      "aggro_depth": 'How deep we will try to get filled with aggro in %',     
      "aggro_order_delay": 'In seconds \n Max delay between aggro orders',     
      "aggro_book_ratio": 'Example : 5\n Ratio between bid/ask at 0.1% spread if bid vs ask ratio is > X  \n allow aggro order ',     
      "aggro_forced_if_no_fill_in": 'Will force an aggro order if you didnt get filled in X seconds',     
      "fill_delay": 'Will wait X seconds to send another order when filled',     
      "algo_type": 'Leave blank or algo_nom \n WARNING: algo_nom target amount is in coin size \n Example: BTCUSDT:1 => you will have 1 BTC'
    }
  return (
    <div className="mobile_body_container">
      
          <div className="strat_btn_container">
            <div className="strat_btn">
              <button value='algo' onClick={(e) => handleChangeTrade(e)} className={strat === "algo" ? 'btn trade_btn active_trade_btn' : 'btn trade_btn'}>algo</button>
            </div>
              <div className="strat_btn">
              <button value='trade' onClick={(e) => handleChangeTrade(e)} className={strat === "trade" ? 'btn trade_btn active_trade_btn' : 'btn trade_btn'}>Trade</button>
              </div>
                
          </div> 
       
        <MobileForm button_list={Object.keys(exchanges_list)} details_infos={details_infos} input_list={inputs} stop_settings_list={stop_settings_list} url={url[strat]} details={details[strat]} strat={strat} {...selectedSetting}/>
        
    </div>
  )
}

export default MobileTrade