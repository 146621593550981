import create from 'zustand'
import { devtools } from 'zustand/middleware'

let colorStore = (set, get) => ({
    activeColor: '#423156',

    setColor: (color) => {
        set((state => {state.activeColor = color}))
    },

})
colorStore = devtools(colorStore)
export const useColorStore = create(colorStore)