import React from 'react'
import Console from "../cards/ConsoleCard"

function MobileConsole() {
    
  return (
    <div className="mobile_body_container">
        <Console mobile={true}/>
    </div>
  )
}

export default MobileConsole