import React, {useState} from "react";
import axios from 'axios';
import {RiArrowDropDownLine} from "react-icons/ri"
import {RiArrowDropUpLine} from "react-icons/ri"
import { MdCancelPresentation } from "react-icons/md";

function Trade(props) {
    const [show, setShow] = useState(true)
    const trade_id = props.infos.trade_id
    const mobile = props.mobile
    // const exchange_id = props.infos.exchange_id
    const instrument = props.infos.instrument
    const side = props.infos.side
    const targets = props.infos.targets
    const positions = props.infos.positions
    const stop_price = props.infos.stop_price
    const exit_price = props.infos.exit_price
    const current_state = props.infos.current_state  // trade state


    const abort = async (trade_id, cancel_type) => {
        const url = `/api/cancel/${cancel_type}/${trade_id}/blub/blub`
        try {
            await axios.get(url, {timeout : 2000})
            } catch (err) {
              console.log(err)
            }
    }    

    const handleExitStopPrice = async (e) => {        
        var data = {
            [e.target.name]:e.target.value,
            trade_id:trade_id
        }
        
        const url = '/api/change_trade_settings'
        try {
            await axios({
               method: "POST",
               url : url,
               data : data
            })
            
            } catch (err) {
              console.log(err)
            }
        e.target.value = ''
    }

  return (
    <li className="li li_state" key={trade_id}>  
        
        
            <div className="state_span_container">
                
                <div className="btn_abort_container">
                <button className="state_btn state_close_btn button" onClick={() => abort(trade_id, 'close_trade')}>close</button>
                <button  className="state_btn state_kill_btn button" onClick={() => abort(trade_id, 'kill_trade')}>kill</button>  
                </div>
                
  
                {side === "BUY" || side === "buy" ? (
                    <span className="state_span state_instrument buy">{instrument}</span> 
                ):(
                    <span className="state_span state_instrument sell">{instrument}</span>  
                )}
                    <span className="state_span current_state">{current_state}</span> 
                    <span className="state_span">|</span> 
                    <form className="exit_stop_price_form" onSubmit={handleExitStopPrice}>
                        <span className="state_span">stop:</span> 
                        <input placeholder={stop_price} className="stop_exit_input" name="stop_price" onBlur={(e) => handleExitStopPrice(e)} type="text"/>
                        
                        {/* <span className="state_span">{stop_price}</span> */}
                        <span className="state_span">|</span>
                        <span className="state_span">exit:</span>
                        <input placeholder={exit_price} className="stop_exit_input" name="exit_price" onBlur={(e) => handleExitStopPrice(e)} type="text"/>
                        <span className="state_span">|</span>
                        {/* <span className="state_span">{exit_price}</span> */}
                        {/* <input type="submit" className="hidden_submit" /> */}
                    </form>
               
                    {show ? (<span className="state_span click" onClick={()=> setShow(!show)} >{mobile ? null : "Targets and Positions"} <RiArrowDropUpLine size={15}/> </span>):
                    (<span className="state_span click" onClick={()=> setShow(!show)} >{mobile ? null : "Targets and Positions"}<RiArrowDropDownLine size={15}/> </span>)}
                    
            </div>
            
            {show && <div className="targets_positions_container">
                <Targets className="targets_container" key={'targets'} name={"Targets"} infos={targets}/>         
                <Positions className="positions_container" key={'positions'} name={"Positions"} infos={positions}/>
            </div>}
            
        </li>
  )
}

export default Trade



const Targets = (props) => {
           
    const targets = props.infos
    const name = props.name
    const abort = async (algo_id) => {
        const url = `/api/cancel/cancel_algo/${algo_id}/blub/blub`
        try {
            const res = await axios.get(url, {timeout : 2000})
            
            } catch (err) {
              console.log(err)
            }
    }
    return (
        <ul className="ul_targets"> 
            <span className="targets_span_name">{name}:</span> 
                {Object.keys(targets).map(function(instrument){
                    return <li className="li_targets" key={instrument}>
                        {targets[instrument]['target'] !== 'Filled' ? (<button className="button state_abort_btn" onClick={() => abort(targets[instrument]['algo_id'])}><MdCancelPresentation className="cancel_icon" size={15}/></button>) : <div></div> }                        
                        <span className="targets_span">{instrument}:</span>
                        <span className="targets_span">{targets[instrument]['target']}</span>
                        </li>
                    }
                )}
        </ul>
        )
    }

const Positions = (props) => {
        
    const positions = props.infos
    const name = props.name
    // console.log('props', props)
    return (
        <ul className="ul_positions"> 
            <span className="targets_span_name">{name}:</span> 
                {Object.keys(positions).map(function(instrument){
                    return <li className="li_targets" key={instrument}>
                        <span className="targets_span">{instrument}:</span>
                        <span className="targets_span">{positions[instrument]}</span>
                        </li>
                    }
                )}
        </ul>
        )
    }


export {Trade, Targets, Positions};