import { useConsoleStore } from "../../stores/store_console";
import {DefaultOutput} from "../subcomponents/DefaultConsoleOutputs"

function Console(props) {
    const dashboard = props.dashboard
    const responsive = props.responsive
    const outputs = useConsoleStore((state) => state.outputs)   
    const switchChannel = useConsoleStore((state) => state.switchChannel)  
    const fetchConsole = useConsoleStore((state) => state.fetchConsole)  
    const selectedChannel = useConsoleStore((state) => state.selectedChannel)  
    const console_btns = ['all', 'summary', 'trades', 'triggers', 'exec', 'error']
    const mobile = props.mobile
    
    const changeChannel = e => {
        e.preventDefault();
        const channel = e.target.name
        switchChannel(channel)
        fetchConsole()
    }
    return (
        <div className={dashboard ? "item5 card": "card"}>
            <div className={responsive ? "background_effect_container_left_col" : "background_effect_container_right"}></div>
            <div className="card_header">
                <h3>Console</h3>
            </div>
            <div className="card_body">
                <div className="console_container">
                    <div className="console_btn_container">
                        {console_btns.map((btn) => {
                            return <button key={btn} name={btn} className={btn === selectedChannel ? "console_btn active_console button" : "console_btn unactive_console button"} onClick={changeChannel}>{btn}</button>
                        })}
                    </div>                    
                    { 
                        (selectedChannel in outputs) ?  
                        (<ul className="console_ul" id ='console_ul' key={selectedChannel}> {
                            outputs[selectedChannel].map((alerts) => {

                                return <DefaultOutput mobile={mobile} key={JSON.stringify(alerts)} props={alerts}/>}
                                )} </ul>) : (
                                    <ul></ul>
                                )
                    }
                </div>
            </div>
        </div>
        
    )
}

export default Console;