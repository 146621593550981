import create from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios';
import produce from 'immer';
import Cookies from 'js-cookie'

// Fetch global PNL, fetch ressources available (USDT, euro, USD)
let arbStore = (set, get) => ({
    arbs: {"arbs":{}},
    arb_count: 0,
    balance:{},
    og_balance:{},
    pnl: 0.0,
    settings: {},
    fetch_pnl: async () => {
      const token = Cookies.get('token')
      try {    
        const res = await axios({
          headers: { Authorization: token },
          method: "GET",
          url:"/api/get_arb_pnl",
          timeout : 2000
      })
      set({pnl : await res.data.pnl})
      
      } catch (err) {
        console.log(err)
      }
    },
    updatePNL: (pnl) => {
      // balance = {'usdt':5000, "euro":1000, "usd":500}
      set(produce(state => {state.pnl = pnl}))
    },
    fetch_balance: async () => {
      const token = Cookies.get('token')
      try {    
        const res = await axios({
          headers: { Authorization: token },
          method: "GET",
          url:"/api/get_kraken_balance",
          timeout : 2000
      })
      set({balance : await res.data.balance})
      set({og_balance : await res.data.og_balance})
      // const test = get().og_balance
      // console.log(test)
      } catch (err) {
        console.log(err)
      }
    },
    updateBalance: (update) => {
      // balance = {'usdt':5000, "euro":1000, "usd":500}
      set(produce(state => {state.balance = update.balance}))
      set(produce(state => {state.og_balance = update.og_balance}))
    },
   

    fetchArb: async () => {
        const token = Cookies.get('token')
        try {    
          const res = await axios({
            headers: { Authorization: token },
            method: "GET",
            url:"/api/arbs_state",
            timeout : 2000
        })
        set({arbs : await res.data})
        
        } catch (err) {
          console.log(err)
        }
        get().countArb()
      },

      updateArb: (arb) => {  
        const arbs = get().arbs 
        const instrument = arb.instrument
        
        set(produce(state => {state.arbs["arbs"][instrument] = arb}))
      },

      addArb: (arb) => {
      
        get().fetchArb()
        get().countArb()
      },
      removeArb: (arb) => {
      
        get().fetchArb()
        get().countArb()
      },

      countArb: () => {
        const arb = get().arbs
        let count = 0
        for (const exchange_id in arb) {
       
          for (const instrument in arb[exchange_id]){

                count ++            
          }
        }

        set({arb_count : count})
      },
      fetchSettings: async () => {
        const token = Cookies.get('token')
        try {    
          const res = await axios({
            headers: { Authorization: token },
            method: "GET",
            url:"/api/get_arb_settings",
            timeout : 2000
        })
        set({settings : await res.data})
        } catch (err) {
          console.log(err)
        }
        get().countArb()
      },
})

arbStore = devtools(arbStore)

export const useArbStore = create(arbStore)