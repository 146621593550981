import React, {Component} from "react";
import axios from 'axios';

import { MdCancelPresentation } from "react-icons/md";


class Algo extends Component {
    constructor(props) {
        super();
    
        this.state = {          
            algo_id:props.infos.algo_id,
            exchange_id:props.infos.exchange_id,
            instrument: props.infos.instrument,
            side: props.infos.side,
            target: props.infos.target,
            qty: props.infos.qty,            
            algo_origin: props.infos.algo_origin,            
        };
    }
    async abort(algo_id, exchange_id){
        
        let url;
        if (exchange_id.includes("CPP")) {
            url = `/api/cancel/cancel_algo/${algo_id}/${exchange_id}/blub`;
        } else {
            url = `/api/cancel/cancel_algo/${algo_id}/blub/blub`;
        }
        try {
            const res = await axios.get(url, {timeout : 2000})
            
            } catch (err) {
              console.log(err)
            }
    }
    
    render() {
        const {algo_id, exchange_id, instrument, side, target, qty, algo_origin} = this.state
        return <li className="li li_state"> 
            <div className="state_span_container">
                <button className="button state_abort_btn" onClick={() => this.abort(algo_id, exchange_id)}> <MdCancelPresentation className="cancel_icon" size={15}/></button>
                {algo_origin && algo_origin === "null" ? "" : algo_origin || ''} 
                {(side) === "BUY" || (side) === "buy" ? (
                    <span className="state_span state_instrument buy">{instrument}</span> 
                ):(
                    <span className="state_span state_instrument sell">{instrument}</span>  
                )}
                <span className="state_span">|</span> 
                <span className="state_span ">Target: {target} $</span>
                <span className="state_span">|</span> 
                <span className="state_span">current qty: {qty}</span>
            </div>
     
        </li>
    }}




export {Algo};