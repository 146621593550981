import React from "react";
import Trade from "./Trade";

import { useTradeStore } from '../../../stores/store_trade'
import { useUserStore } from '../../../stores/store_user'


function TradeStates(props) {
  const trades = useTradeStore((state) => state.trades)
  const userID = useUserStore((state) => state.userID)
  const mobile=props.mobile
  console.log('MObile', mobile)

  return (    
    <div className="states_container"> 

      {Object.keys(trades).map(function(exchange) {
          return (
              <div key={exchange} className="state_exchange_container">
                  <h3 className="states_exchange_title">{exchange.replaceAll(userID+'@', '').replaceAll('_', ' ')}</h3>
                  <ul className="states_ul" key={exchange}>  
                      {Object.keys(trades[exchange]).map(function(instrument) {
                          return Object.keys(trades[exchange][instrument]).map(function(side) {
                              return  Object.keys(trades[exchange][instrument][side]).map(function(trade_id) {
                                  const trade_unit = trades[exchange][instrument][side][trade_id]
                                  const stop_price = trade_unit.stop_price
                                  const exit_price = trade_unit.exit_price
                                  const targets = trade_unit.targets
                                  const positions = trade_unit.positions
                                  return <Trade key={trade_id} infos={trade_unit} mobile={mobile}/>
                                  // return <li key={trade_id}>{targets.BTCUSDT}</li>
                              })
                          })
                      })}
                  </ul>
              </div>
          )
        })
      }
    </div>
  )
}

export default TradeStates