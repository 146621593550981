import React from 'react'

import StandardForm from '../subcomponents/forms/StandardForm'

function AddExchangeCard() {
  const exchanges_list = ["binance", "binance_futures", "bybit_USDT_PERPETUAL", "bybit_SPOT", "kucoin", "kucoin_futures"]
    const input_list = ["exchange_name", "api_key", "api_secret"]
    const url = '/api/make_exchange'
    const strat = 'exchange'
    const details = "undefined"

  return (
    <div className="card" id='add_exchange'>
    <div className="background_effect_container_left_col"></div>
    <div className="card_header">
      <h3>Add Exchange</h3>
    </div>
    <div className="card_body">
    <div className="form_container">
      <StandardForm input_list={input_list} strat={strat} url={url} button_list={exchanges_list} details={details}/>
</div>
    </div>
    </div>
  )
}

export default AddExchangeCard
