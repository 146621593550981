import create from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios';
import produce from 'immer';
import Cookies from 'js-cookie'

let algoStore = (set, get) => ({
  algos: {},
  algo_count : 0,
  fetchAlgos: async () => {
    const token = Cookies.get('token')
    try {    
    const res = await axios({
      headers: { Authorization: token },
      method: "GET",
      url:"/api/algos_state",
      timeout : 2000
    })
    set({algos : await res.data})
    } catch (err) {
      console.log(err)
    }
    get().countAlgos()
  },
  updateAlgo: (algo) => {
    const exchange_id = algo.exchange_id
    const instrument = algo.instrument
    const side = algo.side
    const algo_id = algo.algo_id
    set(produce(state => {state.algos[exchange_id][instrument][side][algo_id] = algo}))
    
  },
  addAlgo: (algo) => {
    // const exchange_id = algo.exchange_id
    // const instrument = algo.instrument
    // const side = algo.side
    // const algo_id = algo.algo_id    
    // set(produce(state => {
    //   state.algos[exchange_id] = state.algos[exchange_id] || {}
    //   state.algos[exchange_id][instrument] = state.algos[exchange_id][instrument] || {}
    //   state.algos[exchange_id][instrument][side] = state.algos[exchange_id][instrument][side] || {}
    //   state.algos[exchange_id][instrument][side][algo_id] = algo
    // }))        
    get().fetchAlgos()
    get().countAlgos()
    
},
  removeAlgo: (algo) => {
    // const exchange_id = algo.exchange_id
    // const instrument = algo.instrument
    // const side = algo.side
    // const algo_id = algo.algo_id
    // set(produce(state => {delete state.algos[exchange_id][instrument][side][algo_id]}))    
    get().fetchAlgos()
    get().countAlgos()
  },
  countAlgos: () => {
    const algos = get().algos
    let count = 0
    for (const exchange in algos) {
      for (const instrument in algos[exchange]){
        for (const side in algos[exchange][instrument]) {
          for (const algo in algos[exchange][instrument][side]){
            count ++            
          }
        }
      }
    }
    set({algo_count : count})
  }
})


algoStore = devtools(algoStore)

export const useAlgoStore = create(algoStore)