import React, {useEffect} from 'react'
import { useSettingsStore } from "../../stores/store_settings";
import { useUserStore } from '../../stores/store_user'

function SettingsDetailsCard() {    
   

    const details = {
        "setting_name": "Aggro, Slow ... anything",
        "max_order_size": 'Order size will be capped at this value, sometimes it can round slightly over if close to min nominal.',  
        "stop_max_order_size": 'Order size of your stops/exits.',  
        "price_limit_is_last_ex_price": '0 or 1, \n your last exPrice becomes you price limit.',   
        "fill_delay": 'Will wait X seconds to send another order when filled.',
        "depth_min": 'Example: 0.001 \n how deep in the book (in %) the order is posted.',     
        "depth_max": 'Example: 0.002 \n random between depth min/depth max (must be >= depth min).', 
        "rdm_qty_range": 'If max order size is 1000 and rdm qty range is 20, the order size will be between 800 and 1000.',  
        "aggro_order_on": '0 or 1, \n will allow to be taker',
        "aggro_depth": 'With aggro order on, How deep you will try to get filled in %.',     
        "aggro_order_delay": 'With aggro order on, \n max delay in seconds between orders.',     
        "aggro_book_ratio": 'Example : 5,\n Ratio between bid/ask at 0.1% spread. Aggro orders will be allowed if bid vs ask ratio is > X.',     
        "aggro_forced_if_no_fill_in": "Will force an aggro order if you didn't get filled in X seconds",  
    }
  return (
    <div className="card">
    <div className="background_effect_container_left_col"></div>
    <div className="card_header">
        <h3>INFOS</h3>
    </div>
    <div className="card_body">
        <div className="settings_infos_container">
            {Object.keys(details).map(function(setting) {
                switch(setting) {
                    case 'setting_name':
                        return null
                    default:
                        return <div className='setting_info_container' key={setting +"_details"}>
                   
                        <h4 className='setting'>{setting.replaceAll("_", " ")}</h4>
                        <p className='setting_info'>{details[setting]}</p>
                    </div>
                }
            })}
        </div>
    </div>
   </div>
  )
}

export default SettingsDetailsCard