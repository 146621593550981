import React, {useState} from 'react'
import {NavLink } from 'react-router-dom'
import Cookies from 'js-cookie'

import { useSettingsStore } from "../../stores/store_settings";
import { useUserStore } from '../../stores/store_user'


import { BsPencilSquare } from "react-icons/bs";


function StateCardSettings(props) {
    const dashboard = props.dashboard
    const responsive = props.responsive
    const mobile = props.mobile
    const settings = useSettingsStore((state) => state.settings)
    const selectSetting = useSettingsStore((state) => state.selectSetting)
    const selectedSetting = useSettingsStore((state) => state.selectedSetting)
    const clearSelectedSetting = useSettingsStore((state) => state.clearSelectedSetting)
    const userID = useUserStore((state) => state.userID)   

    const token = Cookies.get('token')



    const handleShowSetting = (e) => {
        e.preventDefault();
        const setting = e.target.id 
        const details = document.getElementById(setting + "details")
        details.className = 'setting_details_container show_details'
    }
    const handleHideSetting = (e) => {
        e.preventDefault();
        const setting = e.target.id 
        const details = document.getElementById(setting + "details")
        details.className = 'setting_details_container hide_details'
    }
  return (<>
  {mobile ? 
        <ul className="settings_btn_container">
           
        {settings && typeof settings === "object"
        ? Object.keys(settings).map(settingKey => (
            <li className='li setting_li' key={settingKey+ "first"} >
                <p key={settingKey + "_p"} className='setting_name' onClick={() => selectSetting(settingKey)} id={settingKey} >{settingKey.replaceAll('_', ' ')}</p>
                <ul key={settingKey + "_ul"} className='setting_details_container hide_details' id={settingKey + "details"}>
                {Object.keys(settings[settingKey]).map(innerKey => (
                    <li className="setting_detail_li li" key={innerKey}>
                    
                    {innerKey.includes("stop_settings") && Object.keys(settings[settingKey][innerKey]).length !== 0? 
                    <div key={settingKey + "stop_settings_container"} className='stop_settings_container'>
                    <p key={settingKey + "_p_stop_setting"} className='setting_info stop_setting_title'> Stop Settings:</p>
                        <ul key={settingKey + "stop_setting_ul1"} className='stop_setting_ul'>
                            {Object.keys(settings[settingKey][innerKey]).map(stopSetting => (
                                <li key={stopSetting + "_li_" } className='li stop_setting_li'>
                                    <div key={settingKey + "div_stop_setting1"} className='setting_info'>
                                    <p className='param_name'>{stopSetting.replaceAll('_', ' ')} : </p>
                                    <p className='param_info'>{JSON.stringify(settings[settingKey][innerKey][stopSetting]).replaceAll('"', '')}</p>
                                    </div>
                                </li>
                            ))}
                        </ul> 
                        </div>: 
                        <div key={settingKey + "_div2"} className='setting_info'>
                        <p className='param_name'>{innerKey.replaceAll('_', ' ')}:</p> 
                        <p className='param_info'>{JSON.stringify(settings[settingKey][innerKey]).replaceAll('"', '')}</p>
                    </div>}
                    </li>
                ))}
                
                </ul>
            </li>
            ))
        : null}
        </ul>

        :
        <div className={dashboard ? "item4 card": "card"}>
        {responsive ? <div className="background_effect_container_left_col"></div> : <div className="background_effect_container_center"></div>}
        <div className="card_header">
        <h3>Settings</h3>
        {dashboard ? 
            <div className="header_icon_container">
                <NavLink to='/profile'><BsPencilSquare size={20} className='header_icon' /></NavLink>
            </div> 
            :null
        }
        
        </div>
        <div className="card_body">
        <ul className="settings_btn_container">

        {settings && typeof settings === "object"
        ? Object.keys(settings).map(settingKey => (
            <li className='li setting_li' key={settingKey+ "first"} >
                <p key={settingKey + "_p"} className='setting_name' onClick={() => selectSetting(settingKey)} id={settingKey} onMouseOver={(e) => handleShowSetting(e)} onMouseLeave={(e) => handleHideSetting(e)}>{settingKey.replaceAll('_', ' ')}</p>
                <ul key={settingKey + "_ul"} className='setting_details_container hide_details' id={settingKey + "details"}>
                {Object.keys(settings[settingKey]).map(innerKey => (
                    <li className="setting_detail_li li" key={innerKey}>
                    
                    {innerKey.includes("stop_settings") && Object.keys(settings[settingKey][innerKey]).length !== 0? 
                    <div key={settingKey + "stop_settings_container"} className='stop_settings_container'>
                    <p key={settingKey + "_p_stop_setting"} className='setting_info stop_setting_title'> Stop Settings:</p>
                    <ul key={settingKey + "stop_setting_ul1"} className='stop_setting_ul'>
                        {Object.keys(settings[settingKey][innerKey]).map(stopSetting => (
                            <li key={stopSetting + "_li_" } className='li stop_setting_li'>
                                <div key={settingKey + "div_stop_setting1"} className='setting_info'>
                                    <p className='param_name'>{stopSetting.replaceAll('_', ' ')} : </p>
                                    <p className='param_info'>{JSON.stringify(settings[settingKey][innerKey][stopSetting]).replaceAll('"', '')}</p>
                                </div>
                            </li>
                        ))}
                    </ul> 
                    </div>: 
                    <div key={settingKey + "_div2"} className='setting_info'>
                        <p className='param_name'>{innerKey.replaceAll('_', ' ')}:</p> 
                        <p className='param_info'>{JSON.stringify(settings[settingKey][innerKey]).replaceAll('"', '')}</p>
                    </div>}
                    </li>
                ))}
                
                </ul>
            </li>
            ))
        : null}
        </ul>
        </div>
        </div>
        }
  </>
   
  )
}

export default StateCardSettings
