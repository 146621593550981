import React, {useEffect, useState, useRef} from 'react'
import {NavLink } from 'react-router-dom'
import axios from 'axios';
import Cookies from 'js-cookie'
import {HashLink} from "react-router-hash-link"

import { RiDeleteBack2Fill } from "react-icons/ri";
import {RiArrowDropDownLine} from "react-icons/ri"
import {RiArrowDropUpLine} from "react-icons/ri"

import Accordion from './Accordion';
import SearchableDropdown from '../SearchableDropdown';

import { useUserStore } from '../../../stores/store_user'
import { useExchangeStore } from "../../../stores/store_exchange";
import { useSettingsStore } from "../../../stores/store_settings";
import { useUserSettingStore } from '../../../stores/store_user_settings';

function Form(props) {
    const url = props.url;
    const details = props.details;
    const strat = props.strat;
    const stop_settings_list = props.stop_settings_list;
    const input_list = props.input_list;
    const exchanges_list = props.button_list


    //dropdown
    const [isActive, setIsActive] = useState(false);
    const [selected, setIsSelected] = useState("Choose one");
    const [severalInstrument, setSeveralInstruments] = useState(false)
    
    const [selectedAccordion, setSelectedAccordion] = useState(null)
    const [uniqueTarget, setUniqueTarget] = useState(true)
    const [filledInput, setFilledInput] = useState({})
    const [stopSettings, setStopSettings] = useState({})
    const [customSizeOff, setCustomSizeOff] = useState(true)
    const [sideMissing, setSideMissing] = useState(false)
    const [sizeMissing, setSizeMissing] = useState(false)
    const [targetMissing, setTargetMissing] = useState(false)

    const settings = useSettingsStore((state) => state.settings)
    const selectedSetting = useSettingsStore((state) => state.selectedSetting)
    const selectSetting = useSettingsStore((state) => state.selectSetting)
    const clearSelectedSettingStore = useSettingsStore((state) => state.clearSelectedSetting)

    const userSizes = useUserSettingStore((state) => state.sizes)
    const userInstruments = useUserSettingStore((state) => state.instruments)
    const userTargets = useUserSettingStore((state) => state.targets)



    const data_accordion = {"More Options": input_list, "Stop Settings": stop_settings_list}
    const accordion1 = {"More Options": input_list}
    const accordion2 = {"Stop Settings": stop_settings_list}
    const accordion3 = {"Infos": details}

    const clickCounts = useRef({});



    useEffect(() => {
        if(Object.keys(selectedSetting).length !== 0) {
            setFilledInput(prevFilledInput => ({
                ...prevFilledInput,
                ...selectedSetting
            }))
            setStopSettings(prevStopSettings => ({
                ...prevStopSettings,
                ...selectedSetting.stop_settings
            }));
        }
     
    }, [selectedSetting]) 

    const toggleAccordion = (option) => {
        if (selectedAccordion === option) {
            return setSelectedAccordion(null)
            
        }
        
        setSelectedAccordion(option)
    }
   
    const handleStopChange = (event) => {
        const name = event.target.name 
        const value = event.target.value 
        setStopSettings((prevStopSettings) => ({
            ...prevStopSettings,
            [name]: value,
        }))
    }

    const handleTargets = (e) => {
        const targetValue = e.currentTarget.getAttribute('value');
        
        let targetClicks = clickCounts[targetValue] || 0;
        
        targetClicks++;
    
        if (!filledInput.targets) {
            filledInput.targets = "";
        }
    
        const flippedTargets = targetValue.split(' ').map(targ => {
            const [symbol, value] = targ.split(':');
            return `${symbol}:${-parseFloat(value)}`;
        }).join(' ');
    
        const targetValuesArray = targetValue.split(' ');
        const flippedTargetsArray = flippedTargets.split(' ');
    
        if (targetClicks === 1) {
            const newTargets = filledInput.targets + " " + targetValue;
            setFilledInput(prevState => ({
                ...prevState,
                targets: newTargets.trim()
            }));
        } else if (targetClicks === 2) {
            const targetsArray = filledInput.targets.split(' ');
    
            // Replace each sub-target
            targetValuesArray.forEach((tv, idx) => {
                const position = targetsArray.indexOf(tv);
                if (position !== -1) {
                    targetsArray[position] = flippedTargetsArray[idx];
                }
            });
    
            const updatedTargets = targetsArray.join(' ');
    
            setFilledInput(prevState => ({
                ...prevState,
                targets: updatedTargets
            }));
        } else if (targetClicks === 3) {
            const targetsArray = filledInput.targets.split(' ');
    
            // Filter out each sub-target
            const updatedTargetsArray = targetsArray.filter(item => !targetValuesArray.includes(item) && !flippedTargetsArray.includes(item));
    
            const updatedTargets = updatedTargetsArray.join(' ');
    
            setFilledInput(prevState => ({
                ...prevState,
                targets: updatedTargets
            }));
            targetClicks = 0;  
        }
    
        clickCounts[targetValue] = targetClicks;
    }


    const handleChange = (e) => {
        const { name, value, id, checked } = e.target;
         if (name === 'targets' && id === "textareaTargets") {
            setFilledInput(prevState => ({
                ...prevState,
                targets: value
            }));
        } else {
            setFilledInput((prevFilledInput) => ({
                ...prevFilledInput,
                [name]: value,
            }))
        }  
    }
    const targetReworked = (e) => {
        const { name, value } = e.target;
      
        if (name === "targets") {
            const lines = value.split('\n');
            const formattedLines = lines.map(line => {
                const parts = line.split(':');
                if (parts.length === 2) {
                    const target = parts[0];
                    const num = parts[1]
                    const number = num.replaceAll(',', '')
                    const thousands = /\B(?=(\d{3})+(?!\d))/g;
                    const subparts = number.toString().split(".");
                    const integer = subparts[0]
                    const decimal = subparts[1]
                    const withSeparator = integer.replace(thousands, ",") + (decimal ? "." + decimal : "");
                    return `${target}:${withSeparator}`;
                }
                return line;
            })      
          const formattedValue = formattedLines.join('\n');
        //   setFilledInput({ [name]: formattedValue });
          setFilledInput((prevFilledInput) => ({
            ...prevFilledInput,
            [name]: formattedValue,
        }))
          return;
        }
        setFilledInput((prevFilledInput) => ({
            ...prevFilledInput,
            [name]: value,
        }))
        // setFilledInput({ [name]: value });
    }

    const clearSelectedSetting = () => {
        const input_list = [  "setting_name","max_order_size", "cancel_price_gap", "stop_max_order_size","price_limit_is_last_ex_price","fill_delay","depth_min","depth_max", "rdm_qty_range", "aggro_order_on","aggro_depth","aggro_order_delay","aggro_book_ratio","aggro_forced_if_no_fill_in", "stop_settings"]
        
        setFilledInput(current => {
            const updatedInput = { ...current };
            input_list.forEach(inputKey => {
              delete updatedInput[inputKey];
            });
            return updatedInput;
          });
          setStopSettings({})
          clearSelectedSettingStore()
    }
    const showDetails = (e) => {
        e.preventDefault();
        const inputId = e.target.id 
        const details = document.getElementById(inputId + "details")
        details.className = 'input_details show_details'
        console.log('DETRAILS', details, details.className)
    } 
    const hideDetails = (e) => {
        e.preventDefault();
        const inputId = e.target.id 
        const details = document.getElementById(inputId + "details")
        details.className = 'input_details hide_details'
    }
    const handleSubmit = (e, button) => {
        e.preventDefault();
        send_form(button)
    }
    const replaceSpacesWithNewlines = (str) => {
        return str.replace(/ /g, '\n').replace(/,/g, '')
    };
    const  send_form = async (button) => {
        var data = {}
        const newTargets = replaceSpacesWithNewlines(filledInput.targets ? filledInput.targets : "")
        Object.keys(filledInput).map(function(input) {
            return(
                data[input] = filledInput[input]
            )
        }) 
        data['targets'] = newTargets
        data['stop_settings'] = stopSettings
        data['button'] = button  
        // const isValid = validateForm(data);
        const isValid = true
        if (isValid) {
            const token = Cookies.get('token')
            try {
                    const response = await axios({
                        headers: { Authorization: token },
                        method: "POST",
                        url : url,
                        data : data
                    })
                    if (response.status === 200) {
                       
                        
                    }
                } catch (err) {
                    if (err.response.status === 401){                
                        useUserStore.getState().logOut()    
                    }              
                }
            
        } 
    }

    const hasTarget = (target) => {
        const originalValues = target.split(' ').join(' ');
        const flippedValues = target.split(' ').map(targ => {
            const [symbol, value] = targ.split(':');
            return `${symbol}:${-parseFloat(value)}`;
        }).join(' ');
    
        return filledInput.targets && (filledInput.targets.includes(originalValues) || filledInput.targets.includes(flippedValues));
    }

    const getDivClass = (target) => {
    const targetClicks = clickCounts[target] || 0;
    
    // Base class
    let className = "user_targets_container";

    // If the target or its flipped version is in filledInput.targets
    if (hasTarget(target)) {
        if (targetClicks === 1) {
            className += " checked_targets";
        } else if (targetClicks === 2) {
            className += " flipped_targets";
        }
    }

        return className;
    }

    const validateForm = (data) => {
        const { target, targets, side, size } = data;
        if (!target && !targets) {
            setTargetMissing(true)
            return false;
          }
          if (target) {
            setTargetMissing(false)
            if (!side) {
                setSideMissing(true)
                if(!size) {
                    setSizeMissing(true)
                    return false;
                }
              return false;
            }
            
          }
          if (targets) {
            setTargetMissing(false)
            if (!side) {
                setSideMissing(true)
                return false;
              }
          }
          setSizeMissing(false)
          setTargetMissing(false)
          setSideMissing(false)
          return true;

    }

  return (
    <div className='form_container'>
      <form className="form" onSubmit={handleSubmit} autoComplete="off">

        <div className="input_container">
            <h3>Targets</h3>
            <textarea placeholder="BTCUSDT:5000 ETHUSDT:1000"  id='textareaTargets' name='targets' spellCheck="false" onChange={(e) => handleChange(e)} value={filledInput["targets"] || ''} onBlur={(e) => targetReworked(e)} className={targetMissing ? 'textarea input_missing' : "textarea"}rows="8" cols="35" />
        </div>
        <div className="input_container">
            <h3>My Targets</h3>
            <ul className="radio_btn_container form_radio_container">
                {!userTargets && userTargets.length === 0 || Object.keys(userTargets).length ===0 ? 
                    
                        <HashLink to='/profile#add_customized_settings' className="btn link form_hashlink" smooth>Add targets</HashLink>
                   
                    :null   
            }
            {userTargets.map((target, i) => 
                <div  className={getDivClass(target)} id={"targets_container_"+i} key={"target"+i} value={target} onClick={(e) => handleTargets(e)}>
                {target.split(' ').map((targ, index) => {
                    const [symbol, value] = targ.split(':');
                    const formattedValue = parseFloat(value).toLocaleString();
        
                    return (
                        <p className="usertarget" key={targ+"_"+index}>
                            {`${symbol}: ${formattedValue}`}
                        </p>
                    );
                })}
            </div>
            )}
            </ul>
        </div>
  
        <div className="input_container">
            <h3>Settings</h3>
            <ul className="radio_btn_container form_radio_container">
            {
                Object.keys(settings).map(function(setting) {
                    return(
                    <li className="li radio_li" key={setting}>
                    <input key={setting+"input"}type="radio" className="input radio_input form_radio_input" checked={selectedSetting["setting_name"] === setting ? true : false} onChange={() => selectSetting(setting)} onClick={() => selectSetting(setting)} name="setting" id={setting}/>
                    <label key={setting+"label"} className="radio_label" htmlFor={setting}>{setting.replaceAll('_', ' ')}</label>
                  </li>)
                })
            }  
            </ul>
        
        </div>
        <div className="accordion">
            {Object.keys(accordion1).map(function(option, i) {
                return(
                    <div className='item' key={"item" +i}>
                        <div className="accordion_header">
                        <div className="title" key={"title" + i} onClick={() => toggleAccordion(option)}>
                            <h3 key={"h3" +i}>{option}</h3>
                           
                            <span key={"span" +i}>{selectedAccordion === option ? "-" : "+"}</span>
                        </div>
                        {selectedSetting && Object.keys(selectedSetting).length !== 0 ?
                            <div className='clear_selected_setting_container' onClick={() => clearSelectedSetting()} >
                                {selectedSetting["setting_name"].replaceAll('_', ' ')} 
                                <RiDeleteBack2Fill onClick={() => clearSelectedSetting()} className='inline_icon clear_selected_setting_icon'/>
                            </div>
                            : null
                        }
                        </div>
                        
                        {data_accordion[option].map((item, j) => 
                        
                        <div key={"content1_" +j} className={selectedAccordion === option ? "content show" : "content"}>
                            <p className="accordion_p" key={"p" +j}>{item.replaceAll('_', ' ')}</p>
                            <input key={"input" +j} className="input" name={item} id={option} value={filledInput[item] || ''} onChange={(e) => handleChange(e)} type="text" />
                            <div key={"icon" +j} className="info_icon" id={item} onMouseOver={(e) => showDetails(e)} onMouseLeave={(e) => hideDetails(e)}>ⓘ</div>
                            <div key={"detail" +j} name={item} id={item + "details"} className="input_details hide_details">{details[item]}</div>
                        </div>
                        )}
                    </div>
                )
            })}
        </div>
        <div className="accordion">
            {Object.keys(accordion2).map(function(option, i) {
                return(
                    <div className='item' key={"item" +i}>
                        <div className="title" key={"title" + i} onClick={() => toggleAccordion(option)}>
                            <h3 key={"h3_" +i}>{option}</h3>
                            <span key={"span_" +i}>{selectedAccordion === option ? "-" : "+"}</span>
                        </div>
                        {data_accordion[option].map((item, j) => 
                        
                        <div key={"content2_" +j} className={selectedAccordion === option ? "content show" : "content"}>
                            <p className="accordion_p" key={"p" +j}>{item.replaceAll('_', ' ')}</p>
                            <input key={"input_" +j} className="input" name={item} id={option} value={stopSettings[item] || ''} onChange={(e) => handleStopChange(e)} type="text" />
                            <div key={"icon" +j} className="info_icon" id={item +"_stop_"} onMouseOver={(e) => showDetails(e)} onMouseLeave={(e) => hideDetails(e)}>ⓘ</div>
                            <p key={"detail" +j} name={item} id={item + "_stop_details"} className="input_details hide_details">{details[item]}</p>
                            
                        </div>
                        )}
                    </div>
                )
            })}
        </div>
        {exchanges_list && exchanges_list !== "undefined" && exchanges_list.length > 0 ? 
            <div className="std_form_btn_container">
                {exchanges_list.map((button, i) => 
                <button className="btn std_form_btn" type="submit" key={i} value={button} onClick={(e) => handleSubmit(e, button)} >{button.replaceAll('_', ' ')}</button>
                )}
            </div> :
            <div className="std_form_btn_container">
                <HashLink to='/profile#add_exchange' className="btn std_form_btn" smooth>Add Exchanges</HashLink>
            </div>       
        }
      </form>
    </div>
  )
}

export default Form
