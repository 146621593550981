import React from 'react'
import ProfileCard from "../cards/ProfileCard"
import StateCardExchange from "../cards/StateCardExchange"
import AddExchangeCard from "../cards/AddExchangeCard"
import SettingsDetailsCard from '../cards/SettingsDetailsCard'
import SettingsList from '../cards/SettingsList'
import AddSettingCard from "../cards/AddSettingCard"

import UserSettingsCard from '../cards/UserSettingsCard'

function MobileProfile() {
  return (
    <div className='mobile_body_container'>
      {/* <div className="background_effect_container_left_col"></div> */}
      <ProfileCard mobile={true}/>
      <AddExchangeCard />
      <StateCardExchange mobile={true} responsive={true}/>
      <SettingsList responsive={true}/>
      <AddSettingCard mobile={true} responsive={true}/>
      <UserSettingsCard responsive={true}/>
      <SettingsDetailsCard/>
      
    </div>
  )
}

export default MobileProfile