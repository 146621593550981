
function DefaultOutput(infos) {
    const mobile = infos.mobile
    const {output_timestamp, output_type, instrument, avg_price, amount_usd, amount, ex_amount, ex_price, target_left, algo_type, last_rejection, callback_infos, exchange_id, trigger_price, type, trade_id, pnl, exit_origin, duration, error} = infos.props
    const exchange_formatted = exchange_id.split('@')[1]
    var max_length = 30
    var trimmedExchange = exchange_formatted.length > max_length ? exchange_formatted.substring(0, max_length -0) + "..." : exchange_formatted
    var max_length_mobile = 10
    var trimmedExchangeMobile = exchange_formatted.length > max_length_mobile ? exchange_formatted.substring(0, max_length_mobile -0) + "..." : exchange_formatted
    // const duration_formatted = duration ? new Date(duration * 1000).toISOString().substring(11, 19) : 0
    // let output_formatted_time = new Intl.DateTimeFormat('FR', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(output_timestamp * 1000)
    
    

    // Formater la date
    const options_date = { day: '2-digit', month: 'short', year: 'numeric'};
    const options_time = { hour: '2-digit', minute: '2-digit', second: '2-digit' }
    const output_formatted_time = new Intl.DateTimeFormat('FR', options_time).format(output_timestamp * 1000);
    const output_formatted_date = new Intl.DateTimeFormat('FR', options_date).format(output_timestamp * 1000);


    switch(output_type){
        case "execution":
            return <li className="console_li">
            
                <span><span className="console_date">{output_formatted_date}</span><span className="console_time">{output_formatted_time}</span> {mobile ? <div className="clear-after-console-time"></div> : null}<span className="console_exchange">{mobile ? trimmedExchangeMobile.replaceAll('_', " ") : trimmedExchange.replaceAll('_', " ")}</span>: <span className="console_instrument">{instrument}</span> <span className="console_separator">|</span> {amount < 0 ? <span className="negative_amount">${amount} / ₿{ex_amount}</span> : <span className="positive_amount">${amount} / ₿ {ex_amount}</span>} <span className="console_separator">|</span> exPrice: {ex_price} <span className="console_separator">|</span> Remaining: {target_left}</span>            
            </li>  
        case "execution_complete":
            return <li className="console_li">
            {(last_rejection) ? (
                
                <span><span className="console_date">{output_formatted_date}</span><span className="console_time">{output_formatted_time}</span> {mobile ? <div className="clear-after-console-time"></div> : null}{instrument} <span className="console_separator">|</span> {amount < 0 ? <span className="negative_amount">${amount}</span> : <span className="positive_amount">${amount}</span>} <span className="console_separator">|</span> AvgPrice: {avg_price} <span className="console_separator">|</span> Rej: <span className="console_rejection">{last_rejection}</span></span>
            ):(
                <span><span className="console_date">{output_formatted_date}</span><span className="console_time">{output_formatted_time}</span> {mobile ? <div className="clear-after-console-time"></div> : null}{instrument} <span className="console_separator">|</span> {amount < 0 ? <span className="negative_amount">${amount}</span> : <span className="positive_amount">${amount}</span>} <span className="console_separator">|</span> AvgPrice: {avg_price} </span>
            )}
            </li>    
        case "trigger_order_sent":
            const sign = (type === "over") ? ">" : "<"
            return <li className="console_li">                
                <span><span className="console_date">{output_formatted_date}</span><span className="console_time">{output_formatted_time}</span> {mobile ? <div className="clear-after-console-time"></div> : null}Sent Trigger <span className="console_exchange">{mobile ? trimmedExchangeMobile.replaceAll('_', " ") : trimmedExchange.replaceAll('_', " ")}</span>: {instrument} {sign}{trigger_price} <span className="console_separator">|</span> Type: {callback_infos} </span>            
            </li>  
        case "trade_complete":            
            return <li className="console_li">                
                <span><span className="console_date">{output_formatted_date}</span><span className="console_time">{output_formatted_time}</span> {mobile ? <div className="clear-after-console-time"></div> : null}<span className="trade_complete">Trade Complete</span> <span className="console_exchange">{exchange_formatted.replaceAll('_', " ")}</span>: {trade_id} <span className="console_separator">|</span> <span className="console_pnl">Pnl: {pnl}</span>  <span className="console_separator">|</span> Exit: {exit_origin}</span>            
            </li>  
        case "error":            
        return <li className="console_li">                
            <span><span className="console_date">{output_formatted_date}</span><span className="console_time">{output_formatted_time}</span> {mobile ? <div className="clear-after-console-time"></div> : null}{error.replaceAll('_', ' ')} </span>            
        </li>  
        default:
            return (
                <li>{JSON.stringify(infos.props)}</li>
            )
    }
        
    
}

export {DefaultOutput};