import React from 'react'

function Grid(props) {
    const left_grid1 = props.left_grid1
    const left_grid2 = props.left_grid2
    const left_grid3 = props.left_grid3

    const mid_grid1 = props.mid_grid1 
    const mid_grid2 = props.mid_grid2
    const mid_grid3 = props.mid_grid3
    
    const right_grid1 = props.right_grid1
    const right_grid2 = props.right_grid2
    const right_grid3 = props.right_grid3
  return (

        <div className="grid_container grid_35_35_30">
            <div className="col1">
                {left_grid1}
                {left_grid2}
                {left_grid3}
            </div>
            <div className="col2">
               {mid_grid1}
               {mid_grid2}
               {mid_grid3}
            </div>
            <div className="col3">
               {right_grid1}
               {right_grid2}
               {right_grid3}
            </div>
        </div>
  
  )
}

export default Grid
