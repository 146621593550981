import React from 'react'

import {useTriggerStore} from "../../../stores/store_trigger"
import { useUserStore } from '../../../stores/store_user'

import Trigger from './Trigger'

function TriggerStates() {
    const triggers = useTriggerStore((state) => state.triggers)
    const userID = useUserStore((state) => state.userID)

  return (
    <div className="states_container">
        {triggers && Object.keys(triggers).length !== 0  ? Object.keys(triggers).map(function(exchange) {
            return (
                <div className="state_exchange_container" key={exchange}> 
                    <h3 className="states_exchange_title">{exchange.replaceAll(userID+'@', '')}</h3> 
                    <ul className="states_ul" key={exchange}> 
                        {Object.keys(triggers[exchange]).map(function(instrument) {
                            return Object.keys(triggers[exchange][instrument]).map(function(side) {
                                return  Object.keys(triggers[exchange][instrument][side]).map(function(trigger_id) {
                                        const trigger_unit = triggers[exchange][instrument][side][trigger_id]
                                        return <>
                                        {trigger_unit && trigger_unit.infos ? <Trigger key={trigger_id} infos={trigger_unit.infos} /> : null}
                                        </>

                                })
                            })
                        })}
                    </ul>
                </div>
            )
        }) : null}
    </div>
  )
}

export default TriggerStates