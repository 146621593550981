import create from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios';
import produce from 'immer';
import Cookies from 'js-cookie'

let triggerStore = (set, get) => ({
  triggers: {},
  trigger_count: 0,
  
  fetchTriggers: async () => {
    const token = Cookies.get('token')
    try {
    
    const res = await axios({
      headers: { Authorization: token },
      method: "GET",
      url:"/api/triggers_state",
      timeout : 2000
    })
    set({triggers : await res.data})    
    } catch (err) {
      console.log(err)
    }
    get().countTriggers()
  },
  updateTrigger: (trigger) => {
    const exchange_id = trigger.item.infos.exchange_id
    const instrument = trigger.item.infos.instrument
    const type = trigger.item.infos.type
    const trigger_id = trigger.item_id
    set(produce(state => {state.triggers[exchange_id][instrument][type][trigger_id] = trigger.item}))
  },
  addTrigger: (trigger) => {
    
    // const exchange_id = trigger.item.infos.exchange_id
    // const instrument = trigger.item.infos.instrument
    // const type = trigger.item.infos.type
    // const trigger_id = trigger.item_id
    // set(produce(state => {
    //   state.triggers[exchange_id] = state.triggers[exchange_id] || {}
    //   state.triggers[exchange_id][instrument] = state.triggers[exchange_id][instrument] || {}
    //   state.triggers[exchange_id][instrument][type] = state.triggers[exchange_id][instrument][type] || {}
    //   state.triggers[exchange_id][instrument][type][trigger_id] = trigger.item
    // }))
    get().fetchTriggers()
    get().countTriggers()

},
  removeTrigger: (trigger) => {
    // const exchange_id = trigger.item.infos.exchange_id
    // const instrument = trigger.item.infos.instrument
    // const type = trigger.item.infos.type
    // const trigger_id = trigger.item_id
    // set(produce(state => {delete state.triggers[exchange_id][instrument][type][trigger_id]}))
    get().fetchTriggers()
    get().countTriggers()
  },
  countTriggers: () => {
    const triggers = get().triggers 
    let count = 0
    for (const exchange in triggers) {
      for (const instrument in triggers[exchange]){
        for (const type in triggers[exchange][instrument]) {
          for (const trigger in triggers[exchange][instrument][type]){
            count ++            
          }
        }
      }
    }
 
    set({trigger_count : count})
    
  
  }
})


triggerStore = devtools(triggerStore)

export const useTriggerStore = create(triggerStore)