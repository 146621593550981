import React from 'react'

import StateCardOrders from '../cards/SateCardOrders'
import StateCardSettings from "../cards/StateCardSettings"
import Console from '../cards/ConsoleCard'
import ProfileCard from '../cards/ProfileCard'
import TradeCard from '../cards/TradeCard'
import LeftColumn from './LeftColumn'
import TestCard from '../cards/TestCard'




function GridDashBoard() {

  return (

        <div className="grid_container grid_dashboard">
           <LeftColumn dashboard={true} />
           <StateCardOrders dashboard={true} mobile={false}/>
           <ProfileCard dashboard={true} />
           <StateCardSettings dashboard={true} />
     
           <Console dashboard={true} />
        </div>
  
  )
}

export default GridDashBoard