import React, {useState} from 'react'

import ProfileCard from './cards/ProfileCard'
import AddExchangeCard from './cards/AddExchangeCard'
import AddSettingCard from './cards/AddSettingCard'
import StateCardSettings from './cards/StateCardSettings'
import StateCardExchange from './cards/StateCardExchange'
import UserSettingsCard from './cards/UserSettingsCard'


import TestCard from './cards/TestCard'

import Grid from "./layout/Grid"
import { useUserStore } from '../stores/store_user'

import ElephantHead from '../assets/pictures/elefant_head.png'


function Profile(props) {
  const isTablet = props.isTablet

  const logOut = useUserStore((state) => state.logOut)
  const userId = useUserStore((state ) => state.userID)

  const setDarkMode = () => {
    document.querySelector('body').setAttribute('data-theme', "dark")
  }
  const setLightMode = () => {
    document.querySelector('body').setAttribute('data-theme', "light")
  }
  const toggleTheme = (e) => {
    if (e.target.checked) setLightMode();
    else setDarkMode()
  }
  return (
    <>
    
    {isTablet ? 
    <div className="body_container_tablet">
       <ProfileCard/>
      <AddExchangeCard/> 
      <StateCardExchange/>
      <AddSettingCard responsive={true} />
      <StateCardSettings responsive={true}/>
      <UserSettingsCard responsive={true}/>
    </div>
    : <div className="body_container">

      <Grid left_grid1={<ProfileCard/>} left_grid2={<AddExchangeCard/>} left_grid3={<StateCardExchange/>} mid_grid1={<StateCardSettings/>} mid_grid2={<AddSettingCard/>} right_grid1={<UserSettingsCard/>} /> 
   

      </div>
    
    }
    
</>
  )
}

export default Profile
