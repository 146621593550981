import React from 'react'

import StateCardOrders from '../cards/SateCardOrders'
import StateCardSettings from "../cards/StateCardSettings"
import Console from '../cards/ConsoleCard'
import ProfileCard from '../cards/ProfileCard'
 import TradeCard from '../cards/TradeCard'

function DashboardTablet() {
  return (
    <div className="dashboard_tablet">
         <TradeCard tablet={true} />
           <StateCardOrders responsive={true} mobile={false} />
           <Console responsive={true} />
           <ProfileCard tablet={true} />
           <StateCardSettings tablet={true} responsive={true}/>
     
           
    </div>
  )
}

export default DashboardTablet