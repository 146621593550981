import React, { useEffect, useRef, useState } from 'react';
import {NavLink, useNavigate } from 'react-router-dom'
import io from "socket.io-client";

import { AiFillHome } from "react-icons/ai";
import { GiElephantHead, GiWhistle } from "react-icons/gi";
import { FiSettings } from "react-icons/fi";
import { FaCoins } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { TbArrowsExchange } from "react-icons/tb";

import { useUserStore } from '../../stores/store_user'
import { useTradeStore } from '../../stores/store_trade'
import { useAlgoStore } from '../../stores/store_algo'
import { useTriggerStore } from '../../stores/store_trigger'
import { useExchangeStore } from '../../stores/store_exchange'
import {useArbStore} from '../../stores/store_arb'
import { useConsoleStore } from "../../stores/store_console";
import { useSettingsStore } from "../../stores/store_settings";
import { useUserSettingStore } from '../../stores/store_user_settings';




function Navbar() {
  const userID = useUserStore((state) => state.userID)
  const [darkMode, setDarkMode] = useState(false)
  const navigate = useNavigate();

  const fetchUserSettings = useUserSettingStore((state) => state.fecthUserSettings)
  const fetch_settings = useSettingsStore((state) => state.fetchSettings)
  const reload_settings = useSettingsStore((state) => state.reloadSettings)
  const setDefaultSelected = useSettingsStore((state) => state.setDefaultSelected)
  const fetch_console = useConsoleStore((state) => state.fetchConsole)
  const add_output = useConsoleStore((state) => state.addOutput)

  const fetch_trades = useTradeStore((state) => state.fetchTrades)
  const addTrade = useTradeStore((state) => state.addTrade)
  const updateTrade = useTradeStore((state) => state.updateTrade)
  const removeTrade = useTradeStore((state) => state.removeTrade)

  const fetch_algos = useAlgoStore((state) => state.fetchAlgos)
  const addAlgo = useAlgoStore((state) => state.addAlgo)
  const updateAlgo = useAlgoStore((state) => state.updateAlgo)
  const removeAlgo = useAlgoStore((state) => state.removeAlgo)

  const fetch_triggers = useTriggerStore((state) => state.fetchTriggers)
  const addTrigger = useTriggerStore((state) => state.addTrigger)
  const updateTrigger = useTriggerStore((state) => state.updateTrigger)
  const removeTrigger = useTriggerStore((state) => state.removeTrigger)

  const fetch_exchanges = useExchangeStore((state) => state.fetch_exchanges)
  const add_exchange = useExchangeStore((state) => state.add_exchange)
  const remove_exchange = useExchangeStore((state) => state.remove_exchange)

  const fetch_arb = useArbStore((state) => state.fetchArb)
  const addArb = useArbStore((state) => state.addArb)
  const updateArb = useArbStore((state) => state.updateArb)
  const removeArb = useArbStore((state) => state.removeArb)
  const updateBalance = useArbStore((state) => state.updateBalance)
  const updatePNL = useArbStore((state) => state.updatePNL)

  const algoCount = useAlgoStore((state) => state.algo_count)
  const tradeCount = useTradeStore((state) => state.trade_count)
  const triggerCount = useTriggerStore((state) => state.trigger_count)
  const arbCount = useArbStore((state) => state.arb_count)
  const exchangeCount = useExchangeStore((state) => state.exchange_count)

  const settings = useSettingsStore((state) => state.settings)
  const exchanges = useExchangeStore((state) => state.exchanges_list)
  const trades = useTradeStore((state) => state.trades)
  const algos = useAlgoStore((state) => state.algos)
  const triggers = useTriggerStore((state) => state.triggers)
  const arbs = useArbStore((state) => state.arbs)
  const outputs  = useConsoleStore((state) => state.outputs)

  
  const logOut = useUserStore((state) => state.logOut)

  useEffect(() => {    
       
    const fetchData = async () => {
      try {
        await fetchUserSettings();
        await fetch_trades();    
        await fetch_algos();
        await fetch_triggers();
        await fetch_arb();
        await fetch_console();
        await fetch_settings();
        await fetch_exchanges()
        
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log('Authotentication error', error)
          logOut()
        } else {
          console.log("Error fetching data", error);
        }
      }
    };
  
    fetchData();
  }, []);
  
  // useEffect(() => {    
  //   fetch_trades()    
  //   fetch_algos()
  //   fetch_triggers()
  //   fetch_arb()
  //   fetch_exchanges()
  //   fetch_console()
  //   fetch_settings()
    
  // }, [])
  

  return (
    <div className='nav_header'>
        <div className="background_effect"></div>
        <div className="navbar">
          <NavLink to="/" className={({ isActive }) =>isActive ? " navbar_link active_navlink link" : "navbar_link link"}><AiFillHome size={20} className='nav_icon'/>Dashboard</NavLink>
          <NavLink to="/profile" className={({ isActive }) =>isActive ? "navbar_link active_navlink link" : "navbar_link link"}><CgProfile size={20} className='nav_icon'/>Profile</NavLink>

        
      </div>
      
    </div>
  )
}

export default Navbar
