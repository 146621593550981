import React, {useState, useEffect} from 'react'
import { useUserStore } from '../../../stores/store_user'
import Cookies from 'js-cookie'
import axios from 'axios';
import { useSettingsStore } from '../../../stores/store_settings';


function StandardForm(props) {

    const input_list = props.input_list
    const details = props.details
    const button_list = props.button_list
    const strat = props.strat
    const url = props.url
    const input_list2 = props.input_list2
    const input_list2_name = props.input_list2_name
    const details2 =props.details2
    const selectedSetting = props.selectedSetting
    const mobile = props.mobile
    const emptyFields = props.emptyFields

    const clearSelectedSettingStore = useSettingsStore((state) => state.clearSelectedSetting)
    const accordion1 = {[input_list2_name]: input_list2}

    const [filledInput, setFilledInput] = useState({})
    const [filledInput2, setFilledInput2] = useState({})
    const [selectedAccordion, setSelectedAccordion] = useState(null)

    const clearSelectedSetting = () => {
      const input_list = [  "setting_name","max_order_size", "cancel_price_gap", "stop_max_order_size","price_limit_is_last_ex_price","fill_delay","depth_min","depth_max", "rdm_qty_range", "aggro_order_on","aggro_depth","aggro_order_delay","aggro_book_ratio","aggro_forced_if_no_fill_in", "stop_settings"]
      
      setFilledInput(current => {
          const updatedInput = { ...current };
          input_list.forEach(inputKey => {
            delete updatedInput[inputKey];
          });
          return updatedInput;
        });
        setFilledInput2({})
        clearSelectedSettingStore()
  }
  
    useEffect(() => {
      
      if (selectedSetting && selectedSetting != "undefined" && Object.keys(selectedSetting).length !== 0) {
        const {stop_settings, ...setting} = selectedSetting
        setFilledInput(setting)
        setFilledInput2(stop_settings)

      }
      if (emptyFields) {
        const input_list = [  "setting_name","max_order_size", "cancel_price_gap", "stop_max_order_size","price_limit_is_last_ex_price","fill_delay","depth_min","depth_max", "rdm_qty_range", "aggro_order_on","aggro_depth","aggro_order_delay","aggro_book_ratio","aggro_forced_if_no_fill_in", "stop_settings"]
      
      setFilledInput(current => {
          const updatedInput = { ...current };
          input_list.forEach(inputKey => {
            delete updatedInput[inputKey];
          });
          return updatedInput;
        });
        setFilledInput2({})
        clearSelectedSettingStore()
      }
      if (input_list2 && input_list2.length > 0) {
        setSelectedAccordion(input_list2_name)
      }

    }, [selectedSetting, emptyFields])

    const handleChange = (event) => {    
      const name = event.target.name 
      const value = event.target.value  
      setFilledInput((prevFilledInput) => ({
          ...prevFilledInput,
          [name]: value,
      }))    
  }
  const handleChange2 = (event) => {    
    const name = event.target.name 
    const value = event.target.value  
    setFilledInput2((prevFilledInput2) => ({
        ...prevFilledInput2,
        [name]: value,
    }))    
}
  const showDetails = (e) => {
    e.preventDefault();
    const inputId = e.target.id 
    const details = document.getElementById(inputId + "details")
    details.className = 'input_details show_details'
  } 
  const hideDetails = (e) => {
    e.preventDefault();
    const inputId = e.target.id 
    const details = document.getElementById(inputId + "details")
    details.className = 'input_details hide_details'
  }
  const toggleAccordion = (option) => {
    if (selectedAccordion === option) {
        return setSelectedAccordion(null)
    }
    setSelectedAccordion(option)
}
  const handleSubmit = (e, button) => {
    e.preventDefault();
    send_form(button)
  }
  const  send_form = async (button) => {
      var data = {}
      Object.keys(filledInput).map(function(input) {
          return(
              data[input] = filledInput[input]
          )
      }) 
      data[input_list2_name] = filledInput2
      data['button'] = button    
      
      const token = Cookies.get('token')
      try {
          await axios({
          headers: { Authorization: token },
          method: "POST",
          url : url,
          data : data
          })
          setFilledInput({})
          setFilledInput2({})
          } catch (err) {
          if (err.response.status === 401){                
              useUserStore.getState().logOut()    
          }                 
      } 
  }
  return (
 
    <form className="standard_form">
      <div className="inputs_container">
      {input_list.map((input, i )=> 
        <div key={input+"_container"} className="input_container">
          <h3 key={input + "_title"}>{input.replaceAll('_', ' ')}</h3>
          <input key={input+"_input"} type="text" className='input' id="" name={input} value={filledInput[input] || ''} onChange={(e) => handleChange(e)} autoComplete='off'/>
          {details && details != "undefined" ? 
          <div key={"icon" +i} className="info_icon" id={input} onMouseOver={(e) => showDetails(e)} onMouseLeave={(e) => hideDetails(e)}>ⓘ</div>
          : <></>}
          <p key={input + i} name={input} id={input + "details"} className="input_details hide_details">{details[input]}</p>
        
        </div>
        
        )}
        {input_list2  && input_list2.length > 0 ? 
        <div className="accordion">
        {Object.keys(accordion1).map(function(option, i) {
            return(
                <div className='item' key={"item" +i}>
                    <div className="title" key={"title" + i} onClick={() => toggleAccordion(option)}>
                        <h3 key={"h3" +i}>{option.replaceAll('_', ' ')}</h3>
                        <span key={"span" +i}>{selectedAccordion === option ? "-" : "+"}</span>
                    </div>
                    {accordion1[option].map((item, j) => 
                    
                    <div key={"content" +j} className={selectedAccordion === option ? "content show" : "content"}>
                        <p key={"p" +j}>{item.replaceAll('_', ' ')}</p>
                        <input key={"input" +j} className="input" name={item} id="accordion" value={filledInput2[item] || ''} onChange={(e) => handleChange2(e)} type="text" />
                        {details2 && details2 != "undefined" ? 
                        <>
                          <div key={"icon" +j} className="info_icon" id={item} onMouseOver={(e) => showDetails(e)} onMouseLeave={(e) => hideDetails(e)}>ⓘ</div>
                          <p key={"detail" +j} name={item} id={item + "details"} className="input_details hide_details">{details[item]}</p>
                        </> 
                        : null}
                        
                    </div>
                    )}
                </div>
            )
        })}
    </div> 
        : null}
        
      </div>       
        <div className="btn_container">
          {button_list.map(btn =>
            <button className='btn' key={btn} value={btn} onClick={(e) => handleSubmit(e, btn)}>{btn.replaceAll('_', ' ')}</button>
            )}
        </div>
    </form>
  )
}

export default StandardForm
