import React, {useState, useEffect} from 'react'
import { useUserSettingStore } from '../../stores/store_user_settings'
import { useUserStore } from '../../stores/store_user'


import Cookies from 'js-cookie'
import axios from 'axios';

import { BsPlusSquareFill } from "react-icons/bs";
import { TiDelete } from "react-icons/ti";

function UserSettingsCard(props) {
    const responsive = props.responsive
    const fetchUserSettings = useUserSettingStore((state) => state.fecthUserSettings)
    const userTargets = useUserSettingStore((state) => state.targets)
   
    const [targets, setTargets] = useState()
    useEffect(()=>  {
        const fetchData = async () => {
            try {
                await fetchUserSettings()
            } catch (error) {
                console.log("Error fetching user settings", error);
            }
        }
        fetchData();
    }, [])

    const handleTargetsChange = (e) => {
        setTargets(e.target.value)
    }

    const addtargets = async(e) => {
        const token = Cookies.get('token')
        const setting = e.currentTarget.getAttribute('setting_name');
        const data = {
            'req_type': "add",
            "setting": setting,
            "data": targets
        }
        try {
            await axios({
            headers: { Authorization: token },
            method: "POST",
            url : "api/make_user_settings",
            data : data
            })
            setTargets()
            } catch (err) {
            if (err.response.status === 401){                
                useUserStore.getState().logOut()    
            }              
        } 
    }
    const deleteOne = async(e) => {
        const token = Cookies.get('token')
        const value = e.currentTarget.getAttribute('data-id');
        const setting = e.currentTarget.getAttribute('setting_name');
        const data = {
            "req_type": "delete_one", 
            'data': value,
            "setting": setting
        }
        try {
            await axios({
            headers: { Authorization: token },
            method: "POST",
            url : "api/make_user_settings",
            data : data
            })
            } catch (err) {
            if (err.response.status === 401){                
                useUserStore.getState().logOut()    
            }                 
        } 

    }
    const deleteAll = async(e) => {
        const token = Cookies.get('token')
        const value = e.target.name 
        const data = {
            "req_type": "delete_all",
            'setting': value,
        }
        try {
            await axios({
            headers: { Authorization: token },
            method: "POST",
            url : "api/make_user_settings",
            data : data
            })
            } catch (err) {
            if (err.response.status === 401){                
                useUserStore.getState().logOut()    
            }                 
        } 
    }

    return (
    <div className='card' id='add_customized_settings'>
        {responsive ? <div className="background_effect_container_left_col"></div> : <div className="background_effect_container_right"></div>}
        <div className="card_header">
            <h3>Customize</h3>
        </div>
        <div className="card_body">
            <div className="user_setting_container">
            <p>Add one or several targets separated by a space.</p>
                <div className="user_setting_input_container">
                    <h3>Targets</h3>
                    
                    <div>
                        
                        <textarea rows="4" cols="40"  className='input user_setting_textarea' placeholder='BTCUSDT:1000 ETHUSDT:500, BTCUSDT:500 ETHUSDT:250,' setting_name='targets' type="text" value={targets || ""} onChange={(e) => handleTargetsChange(e)} onKeyDown={(e) => {if (e.key === "Enter") {addtargets(e);}}}/>
                        <BsPlusSquareFill  className="user_setting_add_icon" setting_name='targets' onClick={(e) => addtargets(e)} size={18}/>
                    </div>
                </div>
                {userTargets && userTargets.length !== 0 ?
            <button className="button clear_button" name="targets" onClick={(e) => deleteAll(e)}>Clear</button>
            : null
            }
                        
            <div className="user_setting_list_container">
            {/* {userTargets && userTargets.map(userTarget => {
                const parts = userTarget.split(':');
                const coinPair = parts[0];
                const value = parseFloat(parts[1]).toLocaleString();

                return (
                    <li className='li' name={userTarget} key={"user_setting_size_" + userTarget}>
                        <p>{coinPair}: {value}</p>
                        <TiDelete data-id={userTarget} className='delete_user_setting_icon' setting_name="targets" onClick={(e) => deleteOne(e)} size={20} />
                    </li>
                );
            })} */}
            {userTargets && userTargets.map(userTarget => {
                return (
                    <li className='li' key={"user_setting_size_" + userTarget}>
                        {userTarget.split(' ').map(pair => {
                            const parts = pair.split(':');
                            const coinPair = parts[0];
                            const value = parseFloat(parts[1]).toLocaleString();

                            return (
                                <div key={pair}>
                                    <p>{coinPair}: {value}</p>
                                    <TiDelete data-id={userTarget} className='delete_user_setting_icon' setting_name="targets" onClick={(e) => deleteOne(e)} size={20} />
                                </div>
                            );
                        })}
                    </li>
                );
            })}
                
            </div>
            </div>
        </div>
    </div>
      
  )
}

export default UserSettingsCard