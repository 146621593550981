import create from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios';
import produce from 'immer';
import Cookies from 'js-cookie'

let userStore = (set, get) => ({
  userID: Cookies.get('userID'),
  userToken: Cookies.get('token'),
  connected: true,
  loginUser: async (credentials) => {
    const res = await axios({
      method: "POST",
      url : '/api/login_api',
      data : credentials,
      timeout : 50000
    })
    set({userID : await res.data.userID})
    set({userToken : await res.data.token})  
    Cookies.set('token', res.data.token, { expires: res.data.duration }) 
    Cookies.set('userID', res.data.userID, { expires: res.data.duration })  
    set({connected: true})
  },
  verifyToken: async() => {
    const logOut = get().logOut
    
  },
  logOut: () => {    
    set({userID : undefined})
    set({userToken : undefined})  
    set({connected : false})  
    Cookies.remove('token') 
    Cookies.remove('userID')  
  }
})



userStore = devtools(userStore)

export const useUserStore = create(userStore)