import React, {useEffect, useState} from 'react'
import io from "socket.io-client";

import { useUserStore } from '../stores/store_user'
import { useTradeStore } from '../stores/store_trade'
import { useAlgoStore } from '../stores/store_algo'
import { useTriggerStore } from '../stores/store_trigger'
import { useExchangeStore } from '../stores/store_exchange'
import {useArbStore} from '../stores/store_arb'
import { useConsoleStore } from "../stores/store_console";
import { useSettingsStore } from "../stores/store_settings";
import { useUserSettingStore } from '../stores/store_user_settings';
import { json } from 'react-router-dom';

function Sockets() {
  const userID = useUserStore((state) => state.userID)

  const setDefaultUserSetting = useUserSettingStore((state) => state.setDefaultUserSetting)
  const reloadUserSettings = useUserSettingStore((state) => state.reloadUserSettings)

  const reload_settings = useSettingsStore((state) => state.reloadSettings)
  const setDefaultSelected = useSettingsStore((state) => state.setDefaultSelected)

  const add_output = useConsoleStore((state) => state.addOutput)

  const addTrade = useTradeStore((state) => state.addTrade)
  const updateTrade = useTradeStore((state) => state.updateTrade)
  const removeTrade = useTradeStore((state) => state.removeTrade)

  const addAlgo = useAlgoStore((state) => state.addAlgo)
  const updateAlgo = useAlgoStore((state) => state.updateAlgo)
  const removeAlgo = useAlgoStore((state) => state.removeAlgo)

  const addTrigger = useTriggerStore((state) => state.addTrigger)
  const updateTrigger = useTriggerStore((state) => state.updateTrigger)
  const removeTrigger = useTriggerStore((state) => state.removeTrigger)

  const add_exchange = useExchangeStore((state) => state.add_exchange)
  const remove_exchange = useExchangeStore((state) => state.remove_exchange)


  const addArb = useArbStore((state) => state.addArb)
  const updateArb = useArbStore((state) => state.updateArb)
  const removeArb = useArbStore((state) => state.removeArb)
  const updateBalance = useArbStore((state) => state.updateBalance)
  const updatePNL = useArbStore((state) => state.updatePNL)

  const [socket, setSocket] = useState(null)
  const [retryTimeout, setRetryTimeout] = useState(0);
  const [retryCount, setRetryCount] = useState(0);
  const [heartbeatTimeout, setHeartbeatTimeout] = useState(null);
  const [pongTimeout, setPongTimeout] = useState(null);

  const RECONNECT_DELAY = 3000; // 3 seconds
  const RECONNECT_DELAY_BASE = 3000;
  const HEARTBEAT_INTERVAL = 60000; // 60sec
  const PONG_WAIT_TIMEOUT = 15000; // 15 seconds

  useEffect(() => {
    let heartbeatInterval;
    let retryTimeout;

    const sendHeartbeat = () => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({ type: 'ping' }));
      }
    };

    const connectSocket = () => {
      const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || 'ws://localhost:8000/ws';
      const ws = new WebSocket(SOCKET_URL);
      setSocket(ws);

      ws.onopen = () => {
        console.log('WebSocket connection established');
        setRetryCount(0); // Reset retry count on successful connection
        heartbeatInterval = setInterval(sendHeartbeat, HEARTBEAT_INTERVAL);
      };


      ws.onclose = () => {
        clearInterval(heartbeatInterval);
        setSocket(null);
        // Apply exponential backoff for reconnection
        const delay = RECONNECT_DELAY_BASE * Math.pow(2, retryCount);
        retryTimeout = setTimeout(connectSocket, Math.min(delay, 30000)); // Max delay of 30 seconds
        setRetryCount(retryCount + 1);
        console.log(`WebSocket closed, attempting to reconnect in ${delay / 1000} seconds`);
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
      
      ws.onmessage = (event) => {
        // Handle incoming messages
        const jsonData = JSON.parse(event.data);
        const topic = jsonData.channel;
        const data = jsonData.update;
      
      switch (topic) {            
        case userID+'@'+'userSettingUpdate':
          reloadUserSettings(data);
          if (data.update === "delete") {
            setDefaultUserSetting();
          }
          break;
  
        case userID+'@'+'settingUpdate':
          reload_settings(data);
          if (data.update === "delete") {
            setDefaultSelected();
          }
          break;
  
        case userID+'@'+'consoleNew':
          add_output(data);
          break;
  
        case userID+'@'+'exchangeNew':
          add_exchange(data);
          break;
  
        case userID+'@'+'exchangeExited':
          remove_exchange(data.exchange_name);
          break;
  
        case userID+'@'+'tradeUpdate':
          updateTrade(data.item);
          break;
  
        case userID+'@'+'tradeNew':
          addTrade(data.item);
          break;
  
        case userID+'@'+'tradeExited':
          removeTrade(data.item);
          break;
  
        case userID+'@'+'algoUpdate':
          updateAlgo(data.item);
          break;
  
        case userID+'@'+'algoNew':
          addAlgo(data.item);
          break;
  
        case userID+'@'+'algoExited':
          removeAlgo(data.item);
          break;
  
        case userID+'@'+'triggerUpdate':
          updateTrigger(data);
          break;
  
        case userID+'@'+'triggerNew':
          addTrigger(data);
          break;
  
        case userID+'@'+'triggerExited':
          removeTrigger(data);
          break;
  
        case userID+'@'+'arbUpdate':
          updateArb(data.item);
          break;
  
        case userID+'@'+'arbNew':
          addArb(data.item);
          break;
  
        case userID+'@'+'arbExited':
          removeArb(data.item);
          break;
  
        case userID+'@'+'balanceUpdate':
          updateBalance(data);
          break;
  
        case userID+'@'+'updatePNL':
          updatePNL(data.pnl);
          break;
      }
 
      };
    };
    connectSocket();
    return () => {
      if (socket) socket.close();
      if (retryTimeout) clearTimeout(retryTimeout);
      if (heartbeatTimeout) clearTimeout(heartbeatTimeout);
      if (pongTimeout) clearTimeout(pongTimeout);
    };
  }, [])
  // useEffect(() => {

  //   console.log('LISTENING');
    
  //   // Connecting to the WebSocket server
  //   const socket = new WebSocket('ws://localhost:8000/ws');
  //   console.log('socket', socket)
  //   socket.onopen = () => {
  //     console.log('Connected to server!', socket);
  //   };
  
  //   socket.onmessage = (event) => {
      
  //     console.log('MESSAGE', event)
      
  
  //     const jsonData = JSON.parse(event.data);
  //     console.log('JSON', typeof jsonData)
  //     const topic = jsonData.channel; 
  //     const data = jsonData.update
  //     console.log('DATA',  topic, data)
      
  //     switch (topic) {
  //       case userID+'@'+'userSettingUpdate':
  //         console.log('newUserSetting', data);
  //         reloadUserSettings(data);
  //         if (data.update === "delete") {
  //           setDefaultUserSetting();
  //         }
  //         break;
  
  //       case userID+'@'+'settingUpdate':
  //         console.log('newSetting', data);
  //         reload_settings(data);
  //         if (data.update === "delete") {
  //           setDefaultSelected();
  //         }
  //         break;
  
  //       case userID+'@'+'consoleNew':
  //         console.log('newOutput', data);
  //         add_output(data);
  //         break;
  
  //       case userID+'@'+'exchangeNew':
  //         console.log('exchangeNew', data);
  //         add_exchange(data);
  //         break;
  
  //       case userID+'@'+'exchangeExited':
  //         console.log('exchangeExited', data.exchange_name);
  //         remove_exchange(data.exchange_name);
  //         break;
  
  //       case userID+'@'+'tradeUpdate':
  //         console.log('tradeUpdate', data.item);
  //         updateTrade(data.item);
  //         break;
  
  //       case userID+'@'+'tradeNew':
  //         console.log('tradeNew', data.item);
  //         addTrade(data.item);
  //         break;
  
  //       case userID+'@'+'tradeExited':
  //         console.log('exitedTrade', data.item);
  //         removeTrade(data.item);
  //         break;
  
  //       case userID+'@'+'algoUpdate':
  //         console.log('algoUpdate', data.item);
  //         updateAlgo(data.item);
  //         break;
  
  //       case userID+'@'+'algoNew':
  //         console.log('newAlgo', data.item);
  //         addAlgo(data.item);
  //         break;
  
  //       case userID+'@'+'algoExited':
  //         console.log('algoExited', data.item);
  //         removeAlgo(data.item);
  //         break;
  
  //       case userID+'@'+'triggerUpdate':
  //         console.log('triggerUpdate', data);
  //         updateTrigger(data);
  //         break;
  
  //       case userID+'@'+'triggerNew':
  //         console.log('triggerNew', data);
  //         addTrigger(data);
  //         break;
  
  //       case userID+'@'+'triggerExited':
  //         console.log('exitedTrigger', data);
  //         removeTrigger(data);
  //         break;
  
  //       case userID+'@'+'arbUpdate':
  //         console.log('newArb', data.item);
  //         updateArb(data.item);
  //         break;
  
  //       case userID+'@'+'arbNew':
  //         console.log('newArb', data.item);
  //         addArb(data.item);
  //         break;
  
  //       case userID+'@'+'arbExited':
  //         console.log('arbExited', data.item);
  //         removeArb(data.item);
  //         break;
  
  //       case userID+'@'+'balanceUpdate':
  //         console.log('balanceUpdate', data);
  //         updateBalance(data);
  //         break;
  
  //       case userID+'@'+'updatePNL':
  //         console.log('updatePNL', data.pnl);
  //         updatePNL(data.pnl);
  //         break;
  //     }
  //   };
  
  //   socket.onerror = (error) => {
  //     console.error('WebSocket Error here:', error);
  //   };
  
   
  // }, []);




  return null
}

export default Sockets

 // socket.onclose = (event) => {
    //   if (event.wasClean) {
    //     console.log(`Closed cleanly, code=${event.code}, reason=${event.reason}`);
    //   } else {
    //     console.error('Connection died');
    //   }
    // };
  
    // return () => {
    //   if (socket.readyState === 1) { 
    //     console.log('READY TO CLOSE')
    //     socket.close();
    // }
    // };

  // useEffect(() => {
  //   console.log('LISTENING');
  //   const eventSource = new EventSource('http://localhost:8000/sse');
    
  //   eventSource.onopen = function() {
  //     console.log('Connected to SSE server!');
  //   };

  //   eventSource.onmessage = function(event) {
  //     const data = JSON.parse(event.data);
  //     const channel = `${userID}@${data.channel}`;
  //     const json_data = JSON.stringify(data.data);

  //     switch(channel) {
  //       case `${userID}@userSettingUpdate`:
  //         const newUserSetting = JSON.parse(json_data);
  //         reloadUserSettings(newUserSetting);
  //         if (newUserSetting.update === "delete") {
  //           setDefaultUserSetting();
  //         }
  //         break;

  //       case `${userID}@settingUpdate`:
  //         const newSetting = JSON.parse(json_data);
  //         reload_settings(newSetting);
  //         if (newSetting.update === "delete") {
  //           setDefaultSelected();
  //         }
  //         break;

  //       case `${userID}@consoleNew`:
  //         const newOutput = JSON.parse(json_data);
  //         add_output(newOutput);
  //         break;

  //       case `${userID}@exchangeNew`:
  //         const newExchange = JSON.parse(json_data);
  //         add_exchange(newExchange);
  //         break;

  //       case `${userID}@exchangeExited`:
  //         const exitedExchange = JSON.parse(json_data).exchange_name;
  //         remove_exchange(exitedExchange);
  //         break;

  //       case `${userID}@tradeUpdate`:
  //         const newTrade = JSON.parse(json_data).item;
  //         updateTrade(newTrade);
  //         break;

  //       case `${userID}@tradeNew`:
  //         const addedTrade = JSON.parse(json_data).item;
  //         addTrade(addedTrade);
  //         break;

  //       case `${userID}@tradeExited`:
  //         const exitedTrade = JSON.parse(json_data).item;
  //         removeTrade(exitedTrade);
  //         break;

  //       case `${userID}@algoUpdate`:
  //         const newAlgo = JSON.parse(json_data).item;
  //         updateAlgo(newAlgo);
  //         break;

  //       case `${userID}@algoNew`:
  //         const addedAlgo = JSON.parse(json_data).item;
  //         addAlgo(addedAlgo);
  //         break;

  //       case `${userID}@algoExited`:
  //         const exitedAlgo = JSON.parse(json_data).item;
  //         removeAlgo(exitedAlgo);
  //         break;

  //       case `${userID}@triggerUpdate`:
  //         const newTrigger = JSON.parse(json_data);
  //         updateTrigger(newTrigger);
  //         break;

  //       case `${userID}@triggerNew`:
  //         const addedTrigger = JSON.parse(json_data);
  //         addTrigger(addedTrigger);
  //         break;

  //       case `${userID}@triggerExited`:
  //         const exitedTrigger = JSON.parse(json_data);
  //         removeTrigger(exitedTrigger);
  //         break;

  //       case `${userID}@arbUpdate`:
  //         const newArb = JSON.parse(json_data).item;
  //         updateArb(newArb);
  //         break;

  //       case `${userID}@arbNew`:
  //         const addedArb = JSON.parse(json_data).item;
  //         addArb(addedArb);
  //         break;

  //       case `${userID}@arbExited`:
  //         const exitedArb = JSON.parse(json_data).item;
  //         removeArb(exitedArb);
  //         break;

  //       case `${userID}@balanceUpdate`:
  //         const balance = JSON.parse(json_data);
  //         updateBalance(balance);
  //         break;

  //       case `${userID}@updatePNL`:
  //         const pnl = JSON.parse(json_data);
  //         updatePNL(pnl.pnl);
  //         break;

  //       default:
  //         console.error(`Unhandled channel: ${channel}`);
  //     }
  //   };

  //   eventSource.onerror = function(err) {
  //     console.error('EventSource failed:', err);
  //     eventSource.close();
  //   };

  //   return () => {
  //     eventSource.close();
  //   };
  // }, []);
 


  // SOCKETIO
  // useEffect(() => {
  //   console.log('LISTENING')
  //   const socket = io('http://localhost:5000', { withCredentials: true })

   
  //   socket.on('connect', function() {
  //     console.log('Connected to server!');
  //   });
  //   // user settings updates
  //   socket.on(userID+'@'+'userSettingUpdate', json_data => {
      
  //     const newUserSetting = JSON.parse(json_data)
  //     console.log('newUserSetting',newUserSetting)
  //     reloadUserSettings(newUserSetting)    
  //     if (newUserSetting.update === "delete") {
  //       setDefaultUserSetting()
  //     }
  //   })

  //   // settings updates
  //   socket.on(userID+'@'+'settingUpdate', json_data => {
  //     const newSetting = JSON.parse(json_data)
  //     console.log('newSetting', newSetting)
  //     reload_settings(newSetting)    
  //     if (newSetting.update === "delete") {
  //       setDefaultSelected()
  //     }
  //   })
  //   // console updates  
  //   socket.on(userID+'@'+'consoleNew', json_data => {
  //     const newOutput = JSON.parse(json_data)
  //     console.log('newOutput', newOutput)
  //     add_output(newOutput)
  //   })
  //   // exchange updates
  //   socket.on(userID+'@'+'exchangeNew', json_data => { 
  //     const newExchange = JSON.parse(json_data)
  //     console.log('exchangeNew', newExchange)   
  //     add_exchange(newExchange)
  //   })
  //   socket.on(userID+'@'+'exchangeExited', json_data => {
  //     const newExchange = JSON.parse(json_data).exchange_name
  //     console.log('exchangeExited', newExchange)
  //     remove_exchange(newExchange)
  //   })
  //   // Trade updates 
  //   socket.on(userID+'@'+'tradeUpdate', json_data => {
  //     const newTrade = JSON.parse(json_data).item
  //     console.log('tradeUpdate', newTrade)
  //     updateTrade(newTrade)
  //   })
  //   socket.on(userID+'@'+'tradeNew', json_data => {
  //     const newTrade = JSON.parse(json_data).item
  //     console.log('tradeNew', newTrade)
  //     addTrade(newTrade)
  //   })
  //   socket.on(userID+'@'+'tradeExited', json_data => {
      
  //     const exitedTrade = JSON.parse(json_data).item
  //     console.log('exitedTrade', exitedTrade)
  //     removeTrade(exitedTrade)
  //   })

  //   // Algo updates
  //   socket.on(userID+'@'+'algoUpdate', json_data => {
  //     const newAlgo = JSON.parse(json_data).item
  //     console.log('algoUpdate', newAlgo)
  //     updateAlgo(newAlgo)
  //   })
  //   socket.on(userID+'@'+'algoNew', json_data => {      
  //     const newAlgo = JSON.parse(json_data).item
  //     console.log('newAlgo', newAlgo)
  //     addAlgo(newAlgo)
  //   })
  //   socket.on(userID+'@'+'algoExited', json_data => {
  //     const exitedAlgo = JSON.parse(json_data).item
  //     console.log('algoExited', exitedAlgo)
  //     removeAlgo(exitedAlgo)
  //   })

  //   // Trigger updates
  //   socket.on(userID+'@'+'triggerUpdate', json_data => {
  //     const newTrigger = JSON.parse(json_data)
  //     console.log('triggerUpdate', newTrigger)
  //     updateTrigger(newTrigger)
  //   })
  //   socket.on(userID+'@'+'triggerNew', json_data => {   
  //     const newTrigger = JSON.parse(json_data)
  //     console.log('triggerNew', newTrigger)
  //     addTrigger(newTrigger)
  //   })
  //   socket.on(userID+'@'+'triggerExited', json_data => {
  //     const exitedTrigger = JSON.parse(json_data)
  //     console.log('exitedTrigger', exitedTrigger)
  //     removeTrigger(exitedTrigger)
  //   })

  //   // arb updates
  //   socket.on(userID+'@'+'arbUpdate', json_data => {
  //     const newArb = JSON.parse(json_data).item
  //     console.log('newArb', newArb)
  //     updateArb(newArb)
  //   })
  //   socket.on(userID+'@'+'arbNew', json_data => {   
  //     const newArb = JSON.parse(json_data).item
  //     console.log('newArb', newArb)
  //     addArb(newArb)
  //   })
  //   socket.on(userID+'@'+'arbExited', json_data => {
  //     const exitedArb = JSON.parse(json_data).item
  //     console.log('arbExited', exitedArb)
  //     removeArb(exitedArb)
  //   })
  //   socket.on(userID+'@'+'balanceUpdate', json_data => {      
  //     const balance = JSON.parse(json_data)
  //     console.log('balanceUpdate', balance)
  //     updateBalance(balance)
  //   })
  //   socket.on(userID+'@'+'updatePNL', json_data => {      
  //     const pnl = JSON.parse(json_data)
  //     console.log('updatePNL', pnl)
  //     updatePNL(pnl.pnl)
  //   })
  //   return () => {
  //     socket.disconnect();
  // }
  // }, [])