import React, { useState } from 'react';
import { useUserStore } from '../stores/store_user'
import { useBackendHealthStore } from '../stores/store_backend_health';

import { RxAvatar } from "react-icons/rx";

function Login() {
  const loginUser = useUserStore((state) => state.loginUser)
  const connectedServer = useBackendHealthStore((state) => state.setToConnected)
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [alrtMsg, setAlrtMsg] = useState(null)
  const [alert, setAlertOn] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      await loginUser({
        username,
        password
      });
      setAlrtMsg('')
      setAlertOn(false)
      connectedServer()
    } catch (error) {
        console.log("Error login in", error);
        setAlrtMsg("Invalid username or password.")
        setAlertOn(true)
      }   
  }

  return (
    <div className='login_container'>
    <form className='login_form' onSubmit={handleSubmit}>
        <RxAvatar className="login_avatar" size={100}/>
        <div className="background_effect bg_effect_login"></div>
        <h1 className='login_title'><span></span>LogIn<span></span></h1>
        {alert ? <div className='alert_msg'><p>{alrtMsg}</p></div>:<div className='alert_msg'><p></p></div>}
        <div>
            {/* <p>Username</p> */}
            <input className="input" placeholder='Username' type="text" onChange={e => setUserName(e.target.value)} />
        </div>
        <div>
            {/* <p>Password</p> */}
            <input className="input" placeholder='Password' type="password" onChange={e => setPassword(e.target.value)} />
        </div>
        <div>
        
        </div>
            
            <button className="btn" type="submit">Login</button>

    </form>
  </div>
  )
}
export default Login
