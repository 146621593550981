import React from 'react'
import {Algo} from './Algo';
import { useAlgoStore } from '../../../stores/store_algo'
import { useUserStore } from '../../../stores/store_user'

function AlgoStates() {
    const algos = useAlgoStore((state) => state.algos)
    const userID = useUserStore((state) => state.userID) 
 
  return (
    <div className="states_container">
        {Object.keys(algos).map(function(exchange) {
            return (
                <div className="state_exchange_container" key={exchange}> 
                    <h3 className="states_exchange_title">{exchange.replaceAll(userID+'@', '')}</h3> 
                    <ul className="states_ul" key={exchange}> 
                    {Object.keys(algos[exchange]).map(function(instrument) {
                        return Object.keys(algos[exchange][instrument]).map(function(side) {
                            return  Object.keys(algos[exchange][instrument][side]).map(function(algo_id) {
                                    const algo_unit = algos[exchange][instrument][side][algo_id]
                                    return <Algo key={JSON.stringify(algo_unit)} infos={algo_unit} />
                            })
                        })
                    })}
                    </ul>
                </div>
            )
        })}
    </div>
        
  )
}

export default AlgoStates

// maxi@binance: BTCUSDT: SELL: KcWsbtZuIEXdhxy: algo_id: "KcWsbtZuIEXdhxy" algo_origin: null exchange_id: "maxi@binance" execution_report: null exited: false instrument: "BTCUSDT" new: true price_limit: null qty: 0 side: "SELL" target: -50

// {"exchange": {
//     "instrument": {
//         "side": {
//             "algo_id": {
//                 "target": -50,
//                 "quantity": 0,
//                 "algo_origin"
//             }
//         }
//     }
// }}

// const algos = {
//     "exchange": {
//         "instrument": {
//             "side": {
//                 'algo_id': ["target", "quantity", "algo-origin"]
//             }
//         }
//     }
// }

