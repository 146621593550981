import create from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios';
import produce from 'immer';
import Cookies from 'js-cookie'

let consoleStore = (set, get) =>  ({
    outputs: {},
    selectedChannel: 'all',
    fetchConsole: async(channel=get().selectedChannel) => {
    const token = Cookies.get('token')
    const res = await axios({
      headers: { Authorization: token,
        channel : channel},
      method: "GET",
      url : '/api/fetch_console',
      token : token,
      
      timeout : 2000
    })
    set(produce(state => {state.outputs[channel] =  res.data}))
    },
    addOutput: (output_data) => {

      const channels = output_data.console_channels
      const selected = get().selectedChannel
      if (channels.includes(selected) || selected === 'all') {      
       get().fetchConsole()
      }   
     
    },
    switchChannel: (channel) => {
        set({selectedChannel : channel})
        get().fetchConsole()
    }
})

consoleStore = devtools(consoleStore)

export const useConsoleStore = create(consoleStore)