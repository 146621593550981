import create from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios';
import produce from 'immer';
import Cookies from 'js-cookie'

let settingsStore = (set, get) =>  ({
    settings: {},
    selectedSetting: {},

    clearSelectedSetting: () => {
      set({ selectedSetting: {} });
    },
    
    fetchSettings: async() => {
      const token = Cookies.get('token')
      const res = await axios({
      headers: { Authorization: token},
      method: "GET",
      url : '/api/fetch_settings',
      token : token,      
      timeout : 10000
    })
    set(produce(state => {state.settings =  res.data}))
    
    },
    reloadSettings: async(settings_data) => {
      get().fetchSettings()
    },
    selectSetting: (setting_name) => {      
      set({selectedSetting: get().settings[setting_name]})
    },
    setDefaultSelected: () => {
      set({selectedSetting: get().settings["default"]})
    }
    
})

settingsStore = devtools(settingsStore)

export const useSettingsStore = create(settingsStore)