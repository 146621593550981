import create from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios';
import produce from 'immer';
import Cookies from 'js-cookie'

let backendHealthStore = (set, get) => ({
    connected : false,

    setToConnected: () => {
        set({connected: true})
    },
    setToDisconnected: () => {
        set({connected: false})
    }
})
backendHealthStore = devtools(backendHealthStore)

export const useBackendHealthStore = create(backendHealthStore)