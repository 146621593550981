import React, {useState, useEffect} from 'react'
import {NavLink } from 'react-router-dom'
import axios from 'axios';
import Cookies from 'js-cookie'
import {HashLink} from "react-router-hash-link"

import { useUserStore } from '../../../stores/store_user'
import { useSettingsStore } from "../../../stores/store_settings";
import { useUserSettingStore } from '../../../stores/store_user_settings';
import { RiDeleteBack2Fill } from "react-icons/ri";

function MobileForm(props) {
    const exchanges_list = props.button_list
    const stop_settings_list = props.stop_settings_list;
    const input_list = props.input_list;
    const strat = props.strat
    const url = props.url;
    const details = props.details_infos;

    const [filledInput, setFilledInput] = useState({})
    const [stopSettings, setStopSettings] = useState({})
    const [selectedAccordion, setSelectedAccordion] = useState(null)
    const [targetMissing, setTargetMissing] = useState(false)
    const [sideMissing, setSideMissing] = useState(false)
    const [sizeMissing, setSizeMissing] = useState(false)
    const [detailsAccordion, setDetailsAccordion] = useState(false)

    const userTargets = useUserSettingStore((state) => state.targets)
    const settings = useSettingsStore((state) => state.settings)
    const selectedSetting = useSettingsStore((state) => state.selectedSetting)
    const selectSetting = useSettingsStore((state) => state.selectSetting)
    const clearSelectedSettingStore = useSettingsStore((state) => state.clearSelectedSetting)

    const accordion1 = {"More Options": input_list}
    const accordion2 = {"Stop Settings": stop_settings_list}
    const data_accordion = {"More Options": input_list, "Stop Settings": stop_settings_list}

    useEffect(() => {
        if(Object.keys(selectedSetting).length !== 0) {
            setFilledInput(prevFilledInput => ({
                ...prevFilledInput,
                ...selectedSetting
            }))
            setStopSettings(prevStopSettings => ({
                ...prevStopSettings,
                ...selectedSetting.stop_settings
            }));
        }
    }, [selectedSetting]) 

    const handleChange = (e) => {
        const { name, value, id } = e.target;
        
        if (name === 'targets') {
            if (id === "checkboxTargets") {
                setFilledInput(prevState => {
                    let currentTargets = prevState.targets ? prevState.targets.split(" ") : [];
                    
                    // Check if the value already exists in the array
                    if (!currentTargets.includes(value)) {
                        currentTargets.push(value);
                    }
    
                    return {
                        ...prevState,
                        targets: currentTargets.join(" ")
                    };
                });
            } else if (id === "textareaTargets") {
                setFilledInput(prevState => ({
                    ...prevState,
                    targets: value
                }));
            }
        }
        else {
            setFilledInput((prevFilledInput) => ({
                ...prevFilledInput,
                [name]: value,
            }))
        }    

    }

    const targetReworked = (e) => {
        const { name, value } = e.target;
      
        if (name === "targets") {
            const lines = value.split('\n');
            const formattedLines = lines.map(line => {
                const parts = line.split(':');
                if (parts.length === 2) {
                    const target = parts[0];
                    const num = parts[1]
                    const number = num.replaceAll(',', '')
                    const thousands = /\B(?=(\d{3})+(?!\d))/g;
                    const subparts = number.toString().split(".");
                    const integer = subparts[0]
                    const decimal = subparts[1]
                    const withSeparator = integer.replace(thousands, ",") + (decimal ? "." + decimal : "");
                    return `${target}:${withSeparator}`;
                }
                return line;
            })      
          const formattedValue = formattedLines.join('\n');
          setFilledInput({ [name]: formattedValue });
          return;
        }
      
        setFilledInput({ [name]: value });
    }

    const handleStopChange = (event) => {
        const name = event.target.name 
        const value = event.target.value 
        setStopSettings((prevStopSettings) => ({
            ...prevStopSettings,
            [name]: value,
        }))
    }
    const toggleDetailsAccordion = () => {
        setDetailsAccordion(prevState => !prevState);
      }
    const handleSubmit = (e, button) => {
        e.preventDefault();
        send_form(button)
    }

    const clearSelectedSetting = () => {
        const input_list = [  "setting_name","max_order_size", "cancel_price_gap", "stop_max_order_size","price_limit_is_last_ex_price","fill_delay","depth_min","depth_max", "rdm_qty_range", "aggro_order_on","aggro_depth","aggro_order_delay","aggro_book_ratio","aggro_forced_if_no_fill_in", "stop_settings"]
        
        setFilledInput(current => {
            const updatedInput = { ...current };
            input_list.forEach(inputKey => {
              delete updatedInput[inputKey];
            });
            return updatedInput;
          });
          setStopSettings({})
          clearSelectedSettingStore()
    }

    const toggleAccordion = (option) => {
        if (selectedAccordion === option) {
            return setSelectedAccordion(null)
        }
        setSelectedAccordion(option)
    }

    const  send_form = async (button) => {
        var data = {}
        Object.keys(filledInput).map(function(input) {
            return(
                data[input] = filledInput[input]
            )
        }) 
        data['stop_settings'] = stopSettings
        data['button'] = button    
        const isValid = validateForm(data);
        if (isValid) {
            const token = Cookies.get('token')
            try {
                const response =  await axios({
                headers: { Authorization: token },
                method: "POST",
                url : url,
                data : data
                })
                if (response.status === 200) {
                    setFilledInput({})
                    setStopSettings({})
                    clearSelectedSetting()
                    const buy_btn = document.getElementById("buy");
                    const sell_btn = document.getElementById("sell");
                    if (buy_btn) {
                        buy_btn.checked = false;
                    }
                    if (sell_btn) {
                        sell_btn.checked = false;
                    }
                }
                } catch (err) {
                if (err.response.status === 401){                
                    useUserStore.getState().logOut()    
                }              
                }
        } 
    }

    const validateForm = (data) => {
        const { target, targets, side, size } = data;
        if (!target && !targets) {
            setTargetMissing(true)
            return false;
          }
          if (target) {
            setTargetMissing(false)
            if (!size) {
                setSizeMissing(true)
                if(!side) {
                    setSideMissing(true)
                    return false;
                }
              return false;
            } else {
                setSizeMissing(false)
                if(!side) {
                    setSideMissing(true)
                    return false;
                } else {
                    setSideMissing(false)
                    return true
                }
            }
            
          }
          setSizeMissing(false)
          setTargetMissing(false)
          setSideMissing(false)
          return true;

    }

  return (
    <form action="" className='mobile_form' onSubmit={handleSubmit} autoComplete="off" >
        <div className="input_section_container">
        <div className="input_container">
            <h3>Targets</h3>
            <textarea placeholder="BTCUSDT:5000 ETHUSDT:1000"  id='textareaTargets' name='targets' spellCheck="false" onChange={(e) => handleChange(e)}  value={filledInput["targets"] || ''} onBlur={(e) => targetReworked(e)} className={targetMissing ? 'textarea input_missing' : "textarea"}rows="8" cols="35" />
        </div>
        </div>
        {/* <div className="input_container">
            <ul className="radio_btn_container form_radio_container">
                <li className='li radio_li'>
                    <input className="input form_radio_input" type="radio" id="buy" value="buy" onChange={(e) => handleChange(e)} name="side" required="required"/>
                    <label className={sideMissing ? 'side_missing radio_label' : "radio_label"}  id="buy_label" htmlFor="buy">BUY</label>
                </li>
                <li className='li radio_li'>
                    <input className="input form_radio_input" type="radio" id="sell" value="sell" onChange={(e) => handleChange(e)} name="side" required="required"/>
                    <label className={sideMissing ? 'side_missing radio_label' : "radio_label"}  id="sell_label" htmlFor="sell">SELL</label>
                </li>
            </ul>
        </div> */}
        <div className="input_container_settings">
            <h3>My Targets</h3>
            <ul className="radio_btn_container form_radio_container">
            {!userTargets && userTargets.length === 0 || Object.keys(userTargets).length ===0 ? 
                    
                    <HashLink to='/profile#add_customized_settings' className="btn link form_hashlink" smooth>Add targets</HashLink>
               
                :null   
        }
            {userTargets.map((target, i) => {
               
                const [symbol, value] = target.split(':');
                const formattedValue = parseFloat(value).toLocaleString();

                return (
                    <li key={'target_li_' + i} className='li radio_li'>
                        <input 
                            className="input radio_input form_radio_input" 
                            type="checkbox" 
                            id='checkboxTargets' 
                            value={target} 

                            name="targets" 
                            checked={filledInput.targets ? filledInput.targets.split(" ").includes(target) : false} 
                            onChange={(e) => handleChange(e)}
                        />
                        <label className="radio_label" htmlFor={value}>{`${symbol}: ${formattedValue}`}</label>
                    </li>
                );
            })}
            </ul>
        </div>
        <div className="input_container_settings">
            <h3>Settings</h3>
            <ul className="radio_btn_container form_radio_container">
            {
                Object.keys(settings).map(function(setting) {
                    return(
                    <li className="li radio_li" key={setting}>
                    <input key={setting+"input"}type="radio" className="input radio_input form_radio_input" checked={selectedSetting["setting_name"] === setting ? true : false} onChange={() => selectSetting(setting)} onClick={() => selectSetting(setting)} name="setting" id={setting}/>
                    <label key={setting+"label"} className="radio_label" htmlFor={setting}>{setting.replaceAll('_', ' ')}</label>
                  </li>)
                })
            }  
            </ul>
        
        </div>
        <div className="mobile_accordion_container">
             <div className="accordion">
            {Object.keys(accordion1).map(function(option, i) {
                return(
                    <div className='item' key={"item" +i}>
                        <div className="accordion_header">
                        <div className="title" key={"title" + i} onClick={() => toggleAccordion(option)}>
                            <h3 key={"h3" +i}>{option}</h3>
                            <span key={"span" +i}>{selectedAccordion === option ? "-" : "+"}</span>
                        </div>
                        {selectedSetting && Object.keys(selectedSetting).length !== 0 ?
                            <div className='clear_selected_setting_container' onClick={() => clearSelectedSetting()} >
                                {selectedSetting["setting_name"].replaceAll('_', ' ')} 
                                <RiDeleteBack2Fill onClick={() => clearSelectedSetting()} className='inline_icon clear_selected_setting_icon'/>
                            </div>
                            : null
                        }
                        </div>
                        {input_list[strat].map((item, j) => 
                        
                        <div key={"content" +j} className={selectedAccordion === option ? "content show" : "content"}>
                            <p key={"p" +j}>{item.replaceAll('_', ' ')}</p>
                            <input key={"input" +j} className="input" name={item} id={option} value={filledInput[item] || ''} onChange={(e) => handleChange(e)} type="text" />
                        </div>
                        )}
                    </div>
                )
            })}
        </div>
        </div>
       
        <div className="mobile_accordion_container">
        <div className="accordion">
            {Object.keys(accordion2).map(function(option, i) {
                return(
                    <div className='item' key={"item" +i}>
                        <div className="title" key={"title" + i} onClick={() => toggleAccordion(option)}>
                            <h3 key={"h3" +i}>{option}</h3>
                            <span key={"span" +i}>{selectedAccordion === option ? "-" : "+"}</span>
                        </div>
                        
                        {stop_settings_list.map((item, j) => 
                        <div key={"content" +j} className={selectedAccordion === option ? "content show" : "content"}>
                            <p key={"p" +j}>{item.replaceAll('_', ' ')}</p>
                            <input key={"input" +j} className="input" name={item} id={option} value={stopSettings[item] || ''} onChange={(e) => handleStopChange(e)} type="text" />
                        </div>
                        )}
                    </div>
                )
            })}
        </div>
        </div>
        <div className="settings_infos_accordion_container">
      <div className="accordion"  >
        <h3 onClick={() => toggleDetailsAccordion()}>Settings informations <span>{detailsAccordion ? "-" : "+"}</span></h3>
      </div>
      <div className={detailsAccordion ? 'settings_infos_container show_infos' : "settings_infos_container"}>
            {Object.keys(details).map(function(setting) {
                switch(setting) {
                    case 'setting_name':
                        return null
                    default:
                        return <div className='setting_info_container' key={setting +"_details"}>
                   
                        <h4 className='setting'>{setting.replaceAll("_", " ")}</h4>
                        <p className='setting_info'>{details[setting]}</p>
                    </div>
                }
            })}
        </div>
      </div>
        
        {exchanges_list && exchanges_list != "undefined" && exchanges_list.length > 0 ? 
            <div className="std_form_btn_container">
                {exchanges_list.map((button, i) => 
                <button className="btn std_form_btn" type="submit" key={i} value={button} onClick={(e) => handleSubmit(e, button)} >{button.replaceAll('_', ' ')}</button>
                )}
            </div> :
            <div className="std_form_btn_container">
                <HashLink to='/profile#add_exchange' className="btn std_form_btn" smooth>Add Exchanges</HashLink>
            </div>       
        }
    </form>
  )
}

export default MobileForm