import React, {useState} from 'react'

import DashboardTablet from './layout/DashboardTablet';
import GridDashBoard from './layout/GridDashBoard';





function Dashboard(props) {
  const [selectedTab, setSelectedTab] = useState("algo")
  const isTablet = props.isTablet

  return (
    <div className={isTablet ? "body_container_tablet" : "body_container"}>
      {isTablet ? <DashboardTablet/> : <GridDashBoard/>}
      
      {/* <Grid left_grid1={<TradeCard/>}  mid_grid1={<StateCardOrders/>} right_grid1={<StateCardSettings/>} right_grid2={<Console/>}/>       */}
    </div>
  )
}
export default Dashboard
