import React, {useState, useEffect} from 'react'
import { BrowserRouter as Router, Routes, Route,Navigate } from 'react-router-dom';

import './assets/main.css'
import { useUserStore } from './stores/store_user'
import {useColorStore} from './stores/store_color'

import TokenExpirationChecker from "./utils/TokenExpirationChecker"
import Sockets from './utils/Sockets';

import Navbar from './components/layout/Navbar'
import Login from './components/Login'
import Dashboard from './components/Dashboard'
import Profile from "./components/Profile"

import MobileConsole from "./components/MobileComponents/MobileConsole"
import MobileNavbar from './components/MobileComponents/MobileNavbar';
import MobileOrders from './components/MobileComponents/MobileOrders';
import MobileProfile from './components/MobileComponents/MobileProfile';
import MobileTrade from './components/MobileComponents/MobileTrade';


function App() {
  const userToken = useUserStore((state) => state.userToken)
  const userID = useUserStore((state) => state.userID)
  const connected = useUserStore((state) => state.connected)
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false)

  const checkIsMobile = () => {
    const mobileMediaQuery = window.matchMedia('(max-width: 649px)'); 
    
    setIsMobile(mobileMediaQuery.matches);
  };
  const checkIsTablet = () => {
    const mobileMediaQueryTablet = window.matchMedia('(max-width: 1000px)'); 
    
    setIsTablet(mobileMediaQueryTablet.matches);
  };
  

  useEffect(() => {
    checkIsMobile();
    checkIsTablet()
    window.addEventListener('resize', checkIsMobile);
    window.addEventListener('resize', checkIsTablet);
    return () => {
      window.removeEventListener('resize', checkIsMobile);
      window.removeEventListener('resize', checkIsTablet);
    };
  }, []);
  if(typeof userToken === 'undefined' | typeof userID === 'undefined' | !connected )  {
    return (
    <div className='App'>
      <div className='virgin_header'></div>
      <div className="body_container">
      <Login/>
      </div>
    </div>
    
  )}
  
  return (
    <Router>
    <div className="App">
      {/* <TokenExpirationChecker intervalInMilliseconds={5000}/> */}
      <Sockets/>
      {isMobile ? <MobileNavbar/> :<Navbar isTablet={isTablet}/> }
      
      <div className="body">
        <Routes>
          <Route path="/" exact={true} element={isMobile ? <MobileTrade/> : <Dashboard isTablet={isTablet}/>}/>
          <Route path="/profile" exact={true} element={isMobile ? <MobileProfile/> : <Profile isTablet={isTablet}/>}/>
          <Route path="/open_orders" exact={true} element={isMobile ? <MobileOrders/> : <MobileOrders/>}/>
          {isMobile ? <Route path="/console" element={<MobileConsole/>} /> : null}
        
        </Routes>
      </div>

    </div>
    </Router>
  );
}

export default App;
