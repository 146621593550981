import React from 'react'
import { useSettingsStore } from "../../stores/store_settings";
import StandardForm from '../subcomponents/forms/StandardForm';

function AddSettingCard(props) {
  const mobile = props.mobile
  const responsive = props.responsive
    const settings = useSettingsStore((state) => state.settings)
    const selectedSetting = useSettingsStore((state) => state.selectedSetting)

    const input_list = [  
      "setting_name",
      "max_order_size", 
      "stop_max_order_size",          
      "price_limit_is_last_ex_price",  
      "fill_delay",                   
      "depth_min",
      "depth_max", 
      "rdm_qty_range", 
      "aggro_order_on",
      "aggro_depth",
      "aggro_order_delay",
      "aggro_book_ratio",
      "aggro_forced_if_no_fill_in",
      "maker_only"
      ]
      const details = {
        "setting_name": "Aggro, Slow ... anything",
        "max_order_size": 'Order size will be capped at this value, sometimes it can round slightly over if close to min nominal.',  
        "stop_max_order_size": 'Order size of your stops/exits.',  
        "price_limit_is_last_ex_price": '0 or 1, \n your last exPrice becomes you price limit.',   
        "fill_delay": 'Will wait X seconds to send another order when filled.',
        "depth_min": 'Example: 0.001 \n how deep in the book (in %) the order is posted.',     
        "depth_max": 'Example: 0.002 \n random between depth min/depth max (must be >= depth min).', 
        "rdm_qty_range": 'If max order size is 1000 and rdm qty range is 20, the order size will be between 800 and 1000.',  
        "aggro_order_on": '0 or 1, \n will allow to be taker',
        "aggro_depth": 'With aggro order on, How deep you will try to get filled in %.',     
        "aggro_order_delay": 'With aggro order on, \n max delay in seconds between orders.',     
        "aggro_book_ratio": 'Example : 5,\n Ratio between bid/ask at 0.1% spread. Aggro orders will be allowed if bid vs ask ratio is > X.',     
        "aggro_forced_if_no_fill_in": "Will force an aggro order if you didn't get filled in X seconds",  
        "maker_only": "The order will be maker, unless it's an aggro order",
    }
    const stop_settings_list = [
      "max_order_size",                    
      "depth_min",
      "depth_max", 
      "cancel_price_gap",
      "rdm_qty_range", 
      "aggro_order_on",
      "aggro_depth",
      "aggro_order_delay",
      "aggro_book_ratio",
      "aggro_forced_if_no_fill_in",
      "fill_delay",
      "maker_only"
    ]
  
  const input_list2_name = "stop_settings"
  const strat = "setting"
  const btn_list = ['create / apply','remove']
  const url = "/api/make_setting"
  return (
    <div className='card'>
        {responsive ? <div className="background_effect_container_left_col"></div> : <div className="background_effect_container_center"></div>}
        <div className="card_header">
            <h3>Add Settings</h3>
        </div>
        <div className="card_body">
        <StandardForm input_list={input_list} details={mobile ? "undefined" :details} input_list2={stop_settings_list} input_list2_name={input_list2_name} button_list={btn_list} strat={strat} url={url} selectedSetting={selectedSetting}/>
        </div>
      
    </div>
  )
}

export default AddSettingCard
