import create from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios';
import produce from 'immer';
import Cookies from 'js-cookie'

let tradeStore = (set, get) => ({
  trades: {},
  trade_count: 0,
  fetchTrades: async () => {
    const token = Cookies.get('token')
    try {    
    const res = await axios({
      headers: { Authorization: token },
      method: "GET",
      url:"/api/trades_state",
      timeout : 2000
    })

    set({trades : await res.data})    
    } catch (err) {
      console.log(err)
    }
    get().countTrades()
  },
  updateTrade: (trade) => {
    // const exchange_id = trade.exchange_id
    // const instrument = trade.instrument
    // const side = trade.side
    // const trade_id = trade.trade_id
    // set(produce(state => {state.trades[exchange_id][instrument][side][trade_id] = trade}))
    get().fetchTrades()
    get().countTrades()
  },
  addTrade: (trade) => {
    // const exchange_id = trade.exchange_id
    // const instrument = trade.instrument
    // const side = trade.side
    // const trade_id = trade.trade_id
    // set(produce(state => {
    //   state.trades[exchange_id] = state.trades[exchange_id] || {}
    //   state.trades[exchange_id][instrument] = state.trades[exchange_id][instrument] || {}
    //   state.trades[exchange_id][instrument][side] = state.trades[exchange_id][instrument][side] || {}
    //   state.trades[exchange_id][instrument][side][trade_id] = trade
    // }))
    get().fetchTrades()
    get().countTrades()
},
  removeTrade: (trade) => {
    // const exchange_id = trade.exchange_id
    // const instrument = trade.instrument
    // const side = trade.side
    // const trade_id = trade.trade_id
    // set(produce(state => {delete state.trades[exchange_id][instrument][side][trade_id]}))    
    get().fetchTrades()
    get().countTrades()
  },
  countTrades : () => {
    const trades = get().trades
    let count = 0
    for (const exchange in trades) {
      for (const instrument in trades[exchange]){
        for (const side in trades[exchange][instrument]) {
          for (const trade in trades[exchange][instrument][side]){
            count ++            
          }
        }
      }
    }
    set({trade_count : count})
  }
})


tradeStore = devtools(tradeStore)

export const useTradeStore = create(tradeStore)