import React, {useEffect, useState}from 'react'
import { useExchangeStore } from '../../stores/store_exchange'
import { useUserStore } from '../../stores/store_user'
import axios from 'axios';
import Cookies from 'js-cookie'
import { AiFillDelete } from "react-icons/ai";
import {HashLink} from "react-router-hash-link"


function ExchangeStateCard(props) {
  const dashboard = props.dashboard
  const mobile = props.mobile

  // const fectchExchanges = useExchangeStore((state) => state.fetch_exchanges)
  const exchanges_list = useExchangeStore((state) => state.exchanges_list)


 
  const userID = useUserStore((state) => state.userID)   
  const token = Cookies.get('token')
  const kill_exchange = async (exchange_id) => {
      const url = '/api/kill_exchange'
      try {
          await axios({
              headers: { Authorization: token },
              timeout : 2000,
              url : url,
              data : {exchange_id : userID+'@'+exchange_id},
              method: 'POST'
          })
          } catch (err) {
            console.log(err)
          }
  }   
  return (
    <div>
      <div className={dashboard ? "item4 card": "card"}>
      <div className="background_effect_container_left_col"></div>
      <div className="card_header">
          <h3>Exchanges</h3>
      </div>
      <div className="card_body">
        {exchanges_list && exchanges_list.length !== 0 ?
        <ul  className="ul_exchange">     
        {Object.keys(exchanges_list).map(function(exchange) {
            return <li className="exchange_state_container" key={exchange}>
              <AiFillDelete onClick={() => kill_exchange(exchange)} className="delete_icon" size={25}/>
              <h3 className="exchange_title" > {exchange} </h3>
              </li>
        })}
      </ul> : 
          <HashLink className={mobile ? "mobile_add_exchange_hashlink link" : "add_exchange_title link"} to="/profile#add_exchange" smooth>Add an Exchange</HashLink>
        }
        
      </div>
    </div>
    </div>
  )
}

export default ExchangeStateCard
