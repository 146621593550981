import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useUserStore } from '../stores/store_user';
import axios from 'axios';
import { useBackendHealthStore } from '../stores/store_backend_health';

function TokenExpirationChecker({ intervalInMilliseconds }) {
  const logOut = useUserStore((state) => state.logOut);
  const token = Cookies.get('token');
  const [tries, setTries] = useState(3)
  const backendConnected = useBackendHealthStore((state) => state.setToConnected)
  const backendDisConnected = useBackendHealthStore((state) => state.setToDisconnected)

  const checkBackendHealth = async () => {
    try {
      const response = await axios.get('/api/health_check', {
        headers: {
          Authorization: token 
        }
      })
      if (response.status === 200) {
        backendConnected()
        setTries(3);
      }
    } catch (error) {
      if (tries <= 1) {
        backendDisConnected()
        logOut();
      } else {
        setTries(prevTries => prevTries - 1);
        backendDisConnected()
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!token || typeof token === 'undefined' || token === "undefined") {
        clearInterval(interval);
        logOut();
      } else {
        checkBackendHealth();
      }
    }, intervalInMilliseconds);


    return () => {
      clearInterval(interval);
    };
  }, [intervalInMilliseconds, logOut, token, tries]);

    
  return null;
 
}

export default TokenExpirationChecker;


