import React from 'react'

import { useUserStore } from '../../stores/store_user'
import { useBackendHealthStore } from '../../stores/store_backend_health'

import ElephantHead from '../../assets/pictures/elefant_head.png'

function ProfileCard(props) {
  const dashboard = props.dashboard
  const mobile = props.mobile

    const logOut = useUserStore((state) => state.logOut)
    const userId = useUserStore((state ) => state.userID)
    const backendConnected = useBackendHealthStore((state) => state.connected)
  return (
    <div className={dashboard ? 'card profile_card item3' : "card profile_card"}>
    <div className={dashboard ? "background_effect_container_right" : "background_effect_container_left_col"}></div>
    <div className="card_header">
        <h3>Profile</h3> 
        {backendConnected ? 
          <h2 className='server_status online'>Server Online</h2>:
          <h2 className='server_status offline'>Server Offline</h2>}
    </div>
    <div className="card_body">
      <div className="profile_state_container">
        <div className="profile_image_container">
          <img className='profile_image' src={ElephantHead}/>
          <div className="behind_profile_image"></div>
        </div>
        <div className="profile_details">
          <h1 className='profile_user_id'>{userId}</h1>
          

          <button className="logout_btn profile_btn" onClick={() => logOut()}> Log out </button>
        </div>
      </div>
    
      {/* <div className="profile_bg"></div> */}
      

    </div>
    </div>
  )
}

export default ProfileCard