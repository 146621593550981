import React from 'react'
import StateCardOrders from "../cards/SateCardOrders"
import Console from "../cards/ConsoleCard"

function MobileOrders() {
  return (
    <div className="mobile_body_container">
      <StateCardOrders mobile={true}/>
      {/* <Console mobile={true}/> */}
    </div>
  )
}

export default MobileOrders