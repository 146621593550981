import create from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios';
import produce from 'immer';
import Cookies from 'js-cookie'


let exchangeStore = (set, get) => ({
  exchanges_list: [],
  exchange_count: 0,
  add_exchange: async (infos) => {
    const res = await axios({
      method: "POST",
      url : '/api/make_exchange',
      data : infos,
      timeout : 2000
    })
  },
  fetch_exchanges: async () => {
    const token = Cookies.get('token')
    const res = await axios({
      headers: { Authorization: token },
      method: "GET",
      url : '/api/fetch_exchanges',
      token : token,
      timeout : 10000
    })
    set({exchanges_list : await res.data})   
     
  },
  add_exchange: (exchange_infos) => {
    get().fetch_exchanges()
    // set(produce(state => {
    //   state.exchanges_list[exchange_infos.exchange_name] = {}
    // }))       
    // const exchanges_list = get().exchanges_list
    // console.log('exchangelist', exchanges_list)
    // if (exchanges_list.includes(exchange) == false) {
    //   exchanges_list.push(exchange)
    //   console.log('exchangelist push ', exchanges_list)
    //   // set({exchanges_list: exchanges_list})
    //   set(produce(state => {
    //     state.exchanges_list = exchanges_list
    //   }))
    //   const test = get().exchanges_list
    //   console.log(test)
    // }
    
  },
  remove_exchange: (exchange_infos) => {
    get().fetch_exchanges()
    // const exchanges_list = get().exchanges_list
    // const filteredExchanges_list = exchanges_list.filter(exc => exc !== exchange)
    // set({exchanges_list: filteredExchanges_list})
  }
})



exchangeStore = devtools(exchangeStore)

export const useExchangeStore = create(exchangeStore)