import React, {useState} from 'react'
import axios from 'axios';
import {RiArrowDropDownLine} from "react-icons/ri"
import {RiArrowDropUpLine} from "react-icons/ri"

function Trigger(props) {

    const trigger_id = props.infos.trigger_order_id 
    const exchange_id = props.infos.exchange_id 
    const instrument = props.infos.instrument 
    const trigger_price = props.infos.trigger_price 
    const trigger_type = props.infos.type 
    const targets = props.infos.datas.targets || {}
    const side = props.infos.datas.side

    const [show, setShow] = useState(false)

    const abort = async (trigger_id, cancel_type) => {

        const url = `/api/cancel/${cancel_type}/${trigger_id}/${exchange_id}/${instrument}`

        try {
            await axios.get(url, {timeout : 2000})            
            } catch (err) {
              console.log(err)
            }
    }    

  return (
    <li className="li_state" key={trigger_id}>  
            <div className="state_span_container">
                <button  className="button state_btn state_kill_btn" onClick={() => abort(trigger_id, "cancel_trigger")}>kill</button>  
                
                <div className="state_span_container">  
                {side === "BUY" || side === "buy" ? (
                    <span className="state_span state_instrument buy">{instrument}</span> 
                ):(
                    <span className="state_span state_instrument sell">{instrument}</span>  
                )} 
                <span className="state_span">|</span>      
                {(trigger_type) === "over" ? (
                    <span className="state_span">  {">="} {trigger_price} </span> 
                    
                ) : (<span className="state_span">  {'<='} {trigger_price} </span>   )}
                <span className="state_span">|</span>  
                {show ? (<span className="state_span click" onClick={()=> setShow(!show)} >Targets <RiArrowDropUpLine size={15}/> </span>):
                    (<span className="state_span click" onClick={()=> setShow(!show)} >Targets <RiArrowDropDownLine size={15}/> </span>)}
                    {show && <div className="targets_positions_container">
                    <Targets key={'targets'} name={"Targets"} infos={targets}/>  
                    </div>
                    }     
                </div>
            </div>
        </li>
  )
}

export default Trigger

const Targets = (props) => {
           
    const targets = props.infos || {}
    const name = props.name
         
    return (
        <ul className="ul_targets"> 
        <span className="targets_span_name">{name}:</span> 
            {Object.keys(targets).map(function(instrument){
                return <li className="li_targets" key={instrument}>
                        <span className="targets_span">{instrument}:</span>
                        <span className="targets_span">{targets[instrument]}</span>
                    </li>
            })}
    
        </ul>
        )
    }


export {Trigger, Targets};