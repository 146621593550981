import React, {useState} from 'react'
import {NavLink, useNavigate } from 'react-router-dom'
import { LiaExchangeAltSolid } from "react-icons/lia";

import { AiFillHome } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { AiOutlineAlignRight } from "react-icons/ai";
import { VscDebugLineByLine } from "react-icons/vsc";


function MobileNavbar() {
  return (
    <div className="mobile_navbar_container">
        <div className="mobile_navlink_container">
            <NavLink to="/console" className={({ isActive }) => isActive ? "mobile_nav_link mobile_active_link link" : "mobile_nav_link link"}>
              <VscDebugLineByLine  className='mobile_nav_icon'/>
              <h3>Console</h3>
            </NavLink>
        </div>
        <div className="mobile_navlink_container">
            <NavLink to="/open_orders" className={({ isActive }) => isActive ? "mobile_nav_link mobile_active_link link" : "mobile_nav_link link"}>
              <AiOutlineAlignRight  className='mobile_nav_icon'/>
              <h3>My Orders</h3>
            </NavLink>
        </div>
        <div className="mobile_navlink_container">
            <NavLink to="/" className={({ isActive }) => isActive ? "mobile_nav_link mobile_active_link link" : "mobile_nav_link link"}> 
              <LiaExchangeAltSolid  className='mobile_nav_icon'/>
              <h3>Trade</h3>
            </NavLink>
        </div>
        <div className="mobile_navlink_container">
            <NavLink to="/profile" className={({ isActive }) => isActive ? "mobile_nav_link mobile_active_link link" : "mobile_nav_link link"}>
              <CgProfile  className='mobile_nav_icon'/>
              <h3>Profile</h3>
            </NavLink>
        </div>
        
        
  
        
    </div>
  )
}

export default MobileNavbar