import React, {useState, useEffect} from 'react'

import TradeStates from '../subcomponents/states/TradeStates'
import AlgoStates from '../subcomponents/states/AlgoStates'
import TriggerStates from '../subcomponents/states/TriggerStates'

import {useTriggerStore} from "../../stores/store_trigger"
import {useTradeStore} from "../../stores/store_trade"
import { useAlgoStore } from '../../stores/store_algo'

function OrdersStateCard(props) {

    const dashboard = props.dashboard
    const responsive = props.responsive
    const mobile = props.mobile
    const [selectedTab, setSelectedTab] = useState("algo")

    const algoCount = useAlgoStore((state) => state.algo_count)
    const tradeCount = useTradeStore((state) => state.trade_count)
    const triggerCount = useTriggerStore((state) => state.trigger_count)
    
 


    return (
        <div className={dashboard ? "item2 card": "card"}>
            <div className={responsive ? "background_effect_container_left_col" : "background_effect_container_center"}></div>
            <div className="card_header">
                <h3>Open Orders</h3>
            </div>
            <div className="card_body">
            <ul className="card_tab_nav">
                <li className={selectedTab === "algo" ? "active_card_tab li": "li"} onClick={()=> setSelectedTab("algo")}><span>algos {algoCount !== 0 ? <div className='state_count'>{algoCount} </div>: null}</span></li>
                <li className={selectedTab === "trade" ? "active_card_tab li": "li"}  onClick={()=> setSelectedTab("trade")}><span>trades{tradeCount !== 0 ? <div className='state_count'>{tradeCount}</div> : null}</span></li>
                <li className={selectedTab === "trigger" ? "active_card_tab li": "li"}  onClick={()=> setSelectedTab("trigger")}><span>triggers{triggerCount !== 0 ? <div className='state_count'>{triggerCount} </div>: null}</span></li>
            </ul>
            { renderTabContent(selectedTab, mobile) }
            </div>
        </div>
    )
}

export default OrdersStateCard

function renderTabContent(selectedTab, mobile) {
  switch (selectedTab) {
      case 'trade':
          return <TradeStates mobile={mobile}/>;
      case 'algo':
          return <AlgoStates />;
      case 'trigger':
          return <TriggerStates />;
      default:
          return null;
  }
}