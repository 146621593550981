import React, {useState} from 'react'
import Cookies from 'js-cookie'
import axios from 'axios';

import { AiFillDelete } from "react-icons/ai";
import { BsPencilSquare } from "react-icons/bs";

import { useSettingsStore } from "../../stores/store_settings";
import { useUserStore } from '../../stores/store_user'



function SettingsList(props) {
    const responsive = props.responsive
    const settings = useSettingsStore((state) => state.settings)
    const selectSetting = useSettingsStore((state) => state.selectSetting)
   
    const handleShowSetting = (e) => {
        e.preventDefault();
        const setting = e.target.id 
        const details = document.getElementById(setting + "details")
        details.className = 'setting_details_container show_details'
    }
    const handleHideSetting = (e) => {
        e.preventDefault();
        const setting = e.target.id 
        const details = document.getElementById(setting + "details")
        details.className = 'setting_details_container hide_details'
    }

    const deleteSetting = async (e, setting) => {
        e.preventDefault()
        const token = Cookies.get('token')
        var data = {}
        data['button'] = "remove"    
        data['setting_name'] = setting
        try {
            await axios({
            headers: { Authorization: token },
            method: "POST",
            url : "/api/make_setting",
            data : data
            })
            } catch (err) {
            if (err.response.status === 401){                
                useUserStore.getState().logOut()    
            }                 
        } 
    }

  return (
    <div className="card">
        {responsive ? <div className="background_effect_container_left_col"></div> : <div className="background_effect_container_center"></div>}
        <div className="card_header">
        <h3>Settings</h3>
        </div>
        <div className="card_body">
        <ul className="settings_list">
        {settings && typeof settings === "object"
        ? Object.keys(settings).map(settingKey => (
            <li key={settingKey + "li"} className='li setting_li'>
                <div key={settingKey + "div"} className="setting_container">
                    <BsPencilSquare className="inline_icon" size={17} onClick={() => selectSetting(settingKey)}/>
                    <AiFillDelete className="inline_icon" onClick={(e) => deleteSetting(e, settingKey)} size={17}/>
                    <p className='setting_name' id={settingKey} onClick={() => selectSetting(settingKey)} onMouseOver={(e) => handleShowSetting(e)} onMouseLeave={(e) => handleHideSetting(e)}>{settingKey.replaceAll('_', ' ')}</p>
                    <ul className='setting_details_container hide_details' id={settingKey + "details"}>
                        {Object.keys(settings[settingKey]).map(innerKey => (
                            <li className="setting_detail_li li" key={innerKey}>
                            {innerKey.includes("stop_settings") && Object.keys(settings[settingKey][innerKey]).length !== 0? 
                            <div className='stop_settings_container'>
                            <p className='setting_info stop_setting_title'> Stop Settings:</p>
                            <ul className='stop_setting_ul'>
                                {Object.keys(settings[settingKey][innerKey]).map(stopSetting => (
                                    <li className='li stop_setting_li'>
                                        <div className='setting_info'><p className='param_name'>{stopSetting.replaceAll('_', ' ')} : </p><p className='param_info'>{JSON.stringify(settings[settingKey][innerKey][stopSetting]).replaceAll('"', '')}</p></div>
                                    </li>
                                ))}
                            </ul> 
                            </div>: 
                            <div className='setting_info'>
                                <p className='param_name'>{innerKey.replaceAll('_', ' ')}:</p> 
                                <p className='param_info'>{JSON.stringify(settings[settingKey][innerKey]).replaceAll('"', '')}</p>
                            </div>}
                            </li>
                        ))}
                    </ul>
                </div>
            </li>
            ))
        : null}
    </ul>
        </div>
    </div>
  )
}

export default SettingsList