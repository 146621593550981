import React from 'react'
import TradeCard from '../cards/TradeCard'
import TestCard from '../cards/TestCard'
import ToughBots from "../../assets/pictures/tough_bots.png"

function LeftColumn() {

  return (
    <div className="left_column_container">
        <TradeCard/>
       <div className="logo_bg"></div>
        
    </div>
  )
}

export default LeftColumn